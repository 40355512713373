Modal = window.Modal

saveSignature = (e, modal) ->
  e.preventDefault()
  signatureData = $(
    '.draw-signature canvas:visible, canvas.active:visible'
  )[0].toDataURL()

  $('input.signature-data').val(signatureData)
  img = new Image()
  img.src = signatureData
  $('.signature').html(img)

  modal.close()

drawSignatureCanvas = ->
  $('.draw-signature canvas')

resizeSignatureCanvas = ->
  signatureData = window.signature.toDataURL()

  # Set dimensions on the HTML element. If not present the mouse pointer
  # position and the actual drawing on the canvas will be offset
  $canvas = drawSignatureCanvas()
  $canvas[0].width = $canvas.width()
  $canvas[0].height = $canvas.height()

  window.signature.fromDataURL(signatureData)

loadGoogleWebFonts = ->
  window.WebFontConfig =
    google:
      families: [
        'Zeyada::latin'
        'Reenie+Beanie::latin'
        'Mrs+Sheppards::latin'
        'Damion::latin'
        'Rock+Salt::latin'
      ]

  s = document.createElement 'script'
  http = if document.location.protocol is 'https:' then 'https' else 'http'
  s.src = "#{http}://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"
  s.type = 'text/javascript'
  s.async = 'true'

  s0 = (document.getElementsByTagName 'script')[0]
  s0.parentNode.insertBefore s, s0

  window.fontLoader = new MutationObserver(previewTypedSignature)
  window.fontLoader.observe(
    document.documentElement, { attributes: true }
  )


switchToType = (e) ->
  e.preventDefault()

  $('.type-signature').show().find('[data-signature-text]').focus()
  $('.draw-signature').hide()
  previewTypedSignature()
  changeActiveTab(e)
  $(window).off('resize')

switchToDraw = (e) ->
  e.preventDefault()

  $('.type-signature').hide().find('[data-signature-text]').focus()
  $('.draw-signature').show()
  changeActiveTab(e)
  resizeSignatureCanvas()
  $(window).resize resizeSignatureCanvas

changeActiveTab = (e) ->
  $('.modal .tab').removeClass('active')
  $(e.currentTarget).addClass('active')

clearPad = (e) ->
  e.preventDefault()
  window.signature.clear()

drawSignatureFromText = (canvas, text, font) ->
  height = $(canvas).height()
  width = $(canvas).width()
  canvas.height = height
  canvas.width = width
  sidePaddingProportion = .05
  topPaddingProportion = .2
  bottomPaddingProportion = .3
  canvasTopPadding = topPaddingProportion * height
  canvasBottomPadding = bottomPaddingProportion * height
  canvasInnerWidth = width - (width * (sidePaddingProportion * 2))
  canvasInnerHeight = height - canvasBottomPadding - canvasTopPadding
  fontSize = canvasInnerHeight
  context = canvas.getContext('2d')
  canvas.textAlign = 'left'
  context.font = "#{fontSize}px #{font}"
  context.textBaseline = 'middle'

  fontDoesNotFitInCanvas = ->
    context.measureText(text).width > canvasInnerWidth

  while fontDoesNotFitInCanvas()
    fontSize -= 1
    context.font = "#{fontSize}px #{font}"

  context.clearRect(0, 0, width, height)
  context.fillText(
    text, width * sidePaddingProportion, height / 2, canvasInnerWidth
  )

previewTypedSignature = (e) ->
  e?.preventDefault() if e && typeof e.preventDefault == 'function'

  return unless document.documentElement.classList.contains('wf-active')

  text = $('[data-signature-text]').val()

  if text.length
    _.each $('.type-signature canvas'), (canvas) ->
      drawSignatureFromText(canvas, text, canvas.id)

  if window.fontLoader != null
    window.fontLoader.disconnect()
    window.fontLoader = null

selectFont = (e) ->
  e.preventDefault()

  $('canvas.active').removeClass('active')
  $(e.currentTarget).addClass('active')

preloadSignature = (modal) ->
  signature = null
  if previewData = $('.preview-container .signature-data').val()
    signature = previewData
  else if personData = $(modal).find('[data-person-signature]')
    signature = personData.attr('data-person-signature')

  if (signature != null)
    window.signature.fromDataURL(signature)

$.onmount Modal.selector('signature'), ->
  loadGoogleWebFonts()

  window.signature = new SignaturePad(drawSignatureCanvas().get(0))

  preloadSignature(@)

  $('[data-action=clear-signature]').click(clearPad)
  $('[data-action=type-signature]').click switchToType
  $('[data-action=draw-signature]').click switchToDraw
  $('[data-signature-text]').keyup previewTypedSignature
  $('[data-action=select-font]').click selectFont

  $(@).on 'modal:open', (_e, modal) ->
    modal.$el.find('[data-action=save]').click( (e) -> saveSignature(e, modal))

  $(@).on 'modal:close', ->
    delete window.signature
