window.locale_data = $.extend(true, window.default_locale_data, window.locale_data)

String.prototype.t ||= (domain, context, pluralKey, count, args) ->
  TranslationHelper.t @.toString(),
    domain: domain
    context: context
    plural: pluralKey
    count: count
    args: args

class TranslationHelper
  # Translate a key.
  # Allows for the following options:
  #   plural: The default key for the plural version.
  #   count: The count when plural.
  #   args: The values to replace %d, %f, %s, etc. with in the key.
  @t: (key, options = {}) ->
    tStr = ''
    try
      @i18n = @i18n || new Jed
        missing_key_callback: (key) ->
          message = "Undefined localization key: #{key}"
          console.error(message) if window.log_js_errors
        locale_data: window.locale_data
        domain: 'defaults'

      translation = @i18n.translate(key)
      if options.context
        translation = translation.withContext options.context
      if options.domain
        translation = translation.onDomain options.domain
      if options.plural and typeof(options.count) != 'undefined'
        translation = translation.ifPlural options.count, options.plural
      tStr = translation.fetch.apply translation, options.args
    catch e
      tStr = key.toString()

    tStr

window.TranslationHelper = TranslationHelper
