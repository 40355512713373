class Subform
  constructor: (el) ->
    @$el = $(el)

    @data = @$el.data()
    @$el.on 'click', '[data-action=save_subform]', @save
    @$el.on 'click', '[data-action=close_subform]', @close

  @selector: (container) ->
    "[data-subform='#{container}']"

  isValid: =>
    @$el.find('[data-subform-validation*=required]').each ->
      $item = $(@).closest('.list-group-subform-item')
      if $(@).data().validateSelf
        if $(@).val() == ''
          fieldName = $(@).data().errors

          $inputGroup = $item.find("[data-subform-field=#{fieldName}] .input-group")
          $inputGroup.addClass('form-error')
          $inputGroup.children('label').after("<div class='input-help-text form-error-message'>Can't be blank</div>")
      else
        fieldName = $(@).data().validate
        $inputGroup = $item.find("[data-subform-field=#{fieldName}] .input-group")
        $field = $inputGroup.find('input')

        if $field.val() == ''
          $inputGroup.addClass('form-error')
          $inputGroup.children('label').after("<div class='input-help-text form-error-message'>Can't be blank</div>")

    @$el.find('[data-subform-validation*=length]').each ->
      $item = $(@).closest('.list-group-subform-item')
      max = $(@).data().max

      if $(@).data().validateSelf
        if $(@).val().length > max
          fieldName = $(@).data().errors

          $inputGroup = $item.find("[data-subform-field=#{fieldName}] .input-group")
          $inputGroup.addClass('form-error')
          $inputGroup.children('label').after("<div class='input-help-text form-error-message'>Is too long (maximum is #{max} characters)</div>")
      else
        fieldName = $(@).data().validate
        $inputGroup = $item.find("[data-subform-field=#{fieldName}] .input-group")
        $field = $inputGroup.find('input')

        if $field.val().length > max
          $inputGroup.addClass('form-error')
          $inputGroup.children('label').after("<div class='input-help-text form-error-message'>Is too long (maximum is #{max} characters)</div>")

    return @$el.find('.form-error').length == 0

  clearErrors: =>
    @$el.find('.form-error').removeClass('form-error')
    @$el.find('.form-error-message').remove()

  save: =>
    @clearErrors()
    if @isValid()
      @$el.trigger('subform:save')

  close: (e) =>
    e?.preventDefault()
    @$el.trigger('subform:close')

window.Subform = Subform

$(document).on 'append-subform', (_e, item) -> new Subform(item)
