$.onmount 'form.pto-type-form', ->
  functions = {
    toggleApproverTypeLabel: ->
      $approverTypeLabel = $('.approver-type-label')
      $chartIdLabel = $('.chart-id-label')
      if ($(@).val() == 'true')
        $approverTypeLabel.text($approverTypeLabel.data().requests)
        $chartIdLabel.text($chartIdLabel.data().requests)
      else
        $approverTypeLabel.text($approverTypeLabel.data().notifications)
        $chartIdLabel.text($chartIdLabel.data().notifications)
    toggleChartVisibility: ->
      if ($('#pto_type_approver_type').val() == 'manager')
        $('#chart-select').slideDown('normal')
      else
        $('#chart-select').slideUp('normal')
  }

  $datepicker = $(@).find('[data-widget=monthdaypicker]')

  @picker = new Pikaday
    field: $datepicker[0]
    format: 'MMM DD'
    defaultDate: $datepicker.val()
    setDefaultDate: true
    theme: 'hide-year'

  $datepicker.prop('picker', @picker)

  $('[name="pto_type[approval_required]"]').on 'change', functions.toggleApproverTypeLabel
  $('[name="pto_type[approver_type]"]').on 'change', functions.toggleChartVisibility
