jQuery ($) ->
  # Mask inputs
  $.onmount 'input[data-maskedinput]', ->
    $(@).maskInputs() if $.fn.maskInputs

  # Date picker
  $.onmount '[data-widget=datepicker]', ->
    options = {}
    options['maxDate'] = new Date(this.dataset.maxDate) if this.dataset.maxDate
    new PikadayInput(@).create(options)

  if window.gon?.tinymce_style_url
    window.tinymceDefaults = {
      branding: false,
      browser_spellcheck: true,
      content_css: window.gon.tinymce_style_url,
      contextmenu: false,
      formats: {
        underline: { inline: 'u', exact: true },
      },
      height: 200,
      icons: 'built-icons',
      inline_styles: false,
      link_target_list: [
        { text: 'Current tab', value: '' },
        { text: 'New tab', value: '_blank' }
      ],
      menubar: false,
      plugins: 'link lists',
      selector: 'textarea.tinymce',
      toolbar: 'bold italic underline | bullist numlist | link unlink',
      init_instance_callback: (editor) -> (
        editor.on('focus', () -> editor.editorContainer.querySelector('.tox-editor-container')?.classList.add('editor-focus'))
        editor.on('blur', () -> editor.editorContainer.querySelector('.tox-editor-container')?.classList.remove('editor-focus'))
      ),
      setup: (editor) -> (
        editor.on('change', () -> editor.save())
      )
    }

  $.onmount '.tinymce', ->
    tinymce.init(tinymceDefaults)

  # File Input
  $.onmount '.input-file input[type="file"]', ->
    $fileInput = $(@)
    unless $fileInput.hasClass('file-upload') ||
        $fileInput.hasClass('no-file-replace')
      $fileInput.on 'change', (e) ->
        $container = $(this).parent()
        if ($container.find('span').length)
          $container.addClass('disabled')
            .find('span').text('upload_document_disabled'.t('button_defaults'))

  # Cocoon - tool for adding more rows to table form
  $.onmount '.modal .add_fields', ->
    $.cocoon()

  $.onmount '.select select, .relative .input', ->
    $(@).on 'focus', ->
      $(@).parents('.select, .relative').addClass 'active'
    $(@).on 'blur', ->
      $(@).parents('.select, .relative').removeClass 'active'
