$.onmount '#lump, #periodic, #hours-worked', ->
  constants = {
    carryOver: $('#carry-over-template').html()
    accrualTier: $('#accrual-tier-template').html()
    carryOverCount: $('.carry-over-rows').data('count')
    accrualTierCount: $('.accrual-tiers').data('count')
    indexPlaceholder: RegExp('_next_tier_index_', 'g')
  }

  functions = {
    removeTier: ->
      $listGroupItem = $(@).closest('.list-group-item')
      $listGroupItem.slideUp 'normal', ->
        $listGroupItem.remove()

        $additionalAccrualTiers = $('#additional-accrual-tiers .list-group')
        if $additionalAccrualTiers.find('.list-group-item').length == 0
          $additionalAccrualTiers.hide()

    addTier: ->
      $additionalAccrualTiers = $('#additional-accrual-tiers .list-group')
      $additionalAccrualTiers.show()
      $additionalAccrualTiers.append(constants.accrualTier)
      $listGroupItem = $additionalAccrualTiers.find('.list-group-item:last-child')
      $listGroupItem.html($listGroupItem.html().replace(
        constants.indexPlaceholder, constants.accrualTierCount
      ))
      $listGroupItem.slideDown('normal')

      constants.accrualTierCount += 1

    addCarry: ->
      $carryOverRows = $('.carry-over-rows')
      $carryOverRows.append(constants.carryOver)
      $carryOverRow = $carryOverRows.find('.form-inline:last-child')
      first_type = $carryOverRows.find('.form-inline [name="pto_type[pto_carry_overs][0][amount_type]"]').val()
      $carryOverRow.find('[name="pto_type[pto_carry_overs][0][amount_type]"]').val(first_type)
      $carryOverRow.html($carryOverRow.html().replace(
        constants.indexPlaceholder, constants.carryOverCount
      ))
      $carryOverRow.slideDown('normal')

      $carryOverRows.find('[data-action=remove-carry]').show()

      constants.carryOverCount += 1

    removeCarry: ->
      $carryOverRows = $('.carry-over-rows')
      if $carryOverRows.find('.form-inline').length < 3
        $carryOverRows.find('[data-action=remove-carry]').hide()

      $carryOverRow = $(@).closest('.form-inline')
      $carryOverRow.slideUp 'normal', ->
        $carryOverRow.remove()

        functions.triggerBasedOn()

    triggerBasedOn: ->
      show = false

      $('[name$=\\[amount_type\\]] option:selected').each ->
        if $(@).val() == 'percent'
          show = true

      if show
        $('.carry-over-variable').slideDown()
      else
        $('.carry-over-variable').slideUp()
  }

  $tierLink = $(@).find('[data-action=add-tier]')
  $carryLink = $(@).find('[data-action=add-carry]')

  $tierLink.on 'click', functions.addTier
  $carryLink.on 'click', functions.addCarry
  $(@).on 'click', '[data-action=remove-carry]', functions.removeCarry
  $(@).on 'click', '[data-action=remove-tier]', functions.removeTier

  $(@).on 'change', 'select[name$=\\[amount_type\\]]', functions.triggerBasedOn
