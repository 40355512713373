$.onmount '#profile-tabs .is-sortable', ->
  $sortable = $(@)
  $sortable.sortable
    update: ->
      order = $sortable.sortable('toArray', attribute: 'data-sort')

      options = {
        url: $sortable.parent().data('pjax-url'),
        container: '#profile-tabs',
        fragment: '#profile-tabs',
        push: false,
        scrollTo: false,
        data: {
          order: order
        },
        type: 'PUT'
      }

      $.pjax(options)
      $.pjax.xhr = null
