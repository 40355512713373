$.onmount '.edit-position-modal, .new-position-modal, .position-info-modal, .job-definition-modal', ->
  $(this).on 'autocomplete:renderSuggestions', (e) ->
    $nameInput = $(e.target)

    $nameInput.siblings('.autocomplete-container').find('#show-add-org-unit-form').on 'mousedown', (e) ->
      e.preventDefault()
      e.stopPropagation()

      $autocompleteContainer = $nameInput.siblings('.autocomplete-container')
      $autocompleteContainer.hide()

      window.InlineOrgUnitForm.mount
        unitInput: $nameInput[0]
        contextEl: e.currentTarget

  $(this).on 'autocomplete:clearSelected', (e) ->
    targetInputId = $(e.target).data('id')
    # Clears the parent_id input if the reports_to field is cleared
    $("##{targetInputId}").val('') if targetInputId == 'position_parent_id'

  $(this).on 'modal:save', (e, modal, $form) ->
    if gon.selected_chart
      position_id = $form.prop('id').split('edit_position_')[1]
      $.get(
        url: App.endpoint(['positions', 'organize', gon.selected_chart.id])
        data: { ids: [position_id] }
      ).done (data) =>
        position_data = data[0]
        position = App.OrgChart.findPosition(position_data.id)
        position.set(position_data)

        if $(this).hasClass('new-position-modal')
          App.OrgChart.addPosition(position)
        else
          App.OrgChart.updatePosition(position)

    this

  init = =>
    window.BudgetTable.tableEvents(this)
    window.FundingSources.fundingSourceEvents(this)
    window.FilledByInput.events(this)
    window.TitleInput.events(this)
    window.DropdownMenu.handleVisibility(this)

    withOrgUnitAutocompleteBehavior = window.InlineOrgUnitForm.attachOrgUnitAutocompleteBehavior
    $(this).off 'focus', '.inline-org-units', withOrgUnitAutocompleteBehavior
    $(this).on 'focus', '.inline-org-units', withOrgUnitAutocompleteBehavior

    activeTabIndex = 0
    if $('#position-management-tabs').attr('data-selected-tab') == "job_description"
      activeTabIndex = 1
    if $('#position-management-tabs').attr('data-selected-tab') == "planning"
      activeTabIndex = 2

    $(this).find('#position-tab-container').tabs({
      active: activeTabIndex,
      classes: { 'ui-tabs-active': 'active' }
    })

    $(this).on 'modal:error', ->
      # --- Reinitialize handlers on modal error
      init()
      # ---

      error_tab_id = $('.form-error').parents('.tab-panel').attr('id')
      error_link_id = $(".ui-tabs-tab[aria-controls=#{error_tab_id}]").attr('aria-labelledby')
      $("##{error_link_id}").trigger('click')

  init()
