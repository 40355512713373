$.onmount 'form[id=new_company_date]', ->
  $form = $(@)
  $submitBtn = $form.find('input[type=submit]')

  functions = {
    typeChanged: ->
      if $form.find('#company_date_type_ptoblackoutday')[0].checked
        $submitBtn.val($submitBtn.data().blackout)
      else
        $submitBtn.val($submitBtn.data().holiday)
  }

  $form.find('[name="company_date[type]"]').on 'change', functions.typeChanged
