$.onmount "form[id*='new_ical_feed']", ->
  functions = {
    toggleAll: ->
      $listGroup = $(@).closest('.list-group')
      checked = $(@)[0].checked

      $listGroup.find('[name*="ical_feed[calendars]"]').prop('checked', checked)
  }

  $(@).find('[data-action="toggle-all-pto"]').on 'change', functions.toggleAll
  $(@).find('[data-action="toggle-all-other"]').on 'change', functions.toggleAll
