import * as d3 from "d3"
import LineChart from "../../charts/line_chart"

const { $ } = window
let animateLine = true

/**
 * Reloads the widget using the change event of a select or text input.
 *
 * @param {JQuery.Event} changeEvent Delegated event; current target holds new
 *   key.
 * @returns {void}
 * @private
 */
function useEventToReload(changeEvent) {
  const $curr = $(changeEvent.currentTarget)
  const $pjaxContainer = $curr.closest("[data-pjax-render]")
  const baseURL = $pjaxContainer.data("pjaxRender")

  // Dynamically retrieve current group by + time values
  // based on changeEvent - messy but works for now
  let selectedDateRange = null
  let selectedResolution = null
  if ($curr.is("#turnover-resolution")) {
    selectedResolution = $curr.val()
    selectedDateRange = $("#time-range").children(".active").attr("id")
    animateLine = true
  } else {
    selectedDateRange = $curr.attr("id")
    selectedResolution = $("#turnover-resolution").children("[selected]").val()
    animateLine = true
  }

  if (!baseURL) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to reload widget using '${baseURL}'`)
    return
  }

  $.pjax({
    url: baseURL,
    data: {
      date_range: selectedDateRange,
      resolution: selectedResolution,
    },
    // I'm not sure this selector is unique enough, but it should work for now.
    container: `[data-pjax-render='${baseURL}']`,
    replace: false,
    push: false,
    scrollTo: false,
  })
}

// Handles the time range button group
$.onmount(".chart-controls", () => {
  // Time controls
  $("#time-range")
    .children(".btn")
    .each((i, d) => {
      const button = $(d)
      button.on("click", (event) => {
        const clickedButton = $(event.currentTarget).attr("id")
        const activeButton = $("#time-range").children(".active").attr("id")
        if (clickedButton === activeButton) {
          return
        }
        // Remove active class from buttons
        d3.select("#time-range").selectAll(".btn").classed("active", false)
        // Set current click to active
        d3.select(event.currentTarget).classed("active", true)

        useEventToReload(event)
      })
    })
})

// Handle mounting of charts - this gets run every time there is a new data
// selection
$.onmount(".turnover-widget", (widget) => {
  // Instantiate class
  const turnoverLine = new LineChart({
    options: {},
  })

  // Remove overflow-y class from parent container
  $(widget.selector).closest(".grid-stack-item-content").css({
    overflowY: "hidden",
  })

  // Set Line options
  turnoverLine.useAnimation = !!animateLine
  animateLine = false

  // Create charts
  turnoverLine.createChart({ selector: ".line-chart" })

  // Handle resizing the line chart
  $(window).on("resize", () => {
    turnoverLine.resizeChart()
  })
})

// Handling select reload
$("body").on("change", "[data-pjax-reload-turnover]", useEventToReload)
