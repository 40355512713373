const $ = window.jQuery
const Modal = window.Modal
const Pjax = window.Pjax

$.onmount(Modal.selector("person-send-invitation"), ({ selector }) => {
  $(selector).on("modal:save", (_e, modal) => {
    modal.reloadTargetContainer()
    if (modal.data.modalAfterUrl) {
      $.pjax({
        url: modal.data.modalAfterUrl,
        container: Pjax.targetContainer("modal-content"),
        fragment: Pjax.targetContainer("modal-content"),
        push: false,
        scrollTo: false,
      })
      $.pjax.xhr = null
    }
  })
})
