updateCount = (count) ->
  $('#list-member-count').text(count)
  if count == 1
    $('#list-member-text').text('List Member'.t('org_chart'))
  else
    $('#list-member-text').text('List Members'.t('org_chart'))

removeListPersonRow = (e) ->
  $(e.currentTarget).parents('tr').remove()
  validateForm()

  updateCount(parseInt($('#list-member-count').text()) - 1)

addListPersonRow = (personId, personFullName) ->
  newRow = "
    <tr id=#{personId}>
      <td>
        #{personFullName}
        <input type='hidden' name='list[list_people][][person_id]' value=#{personId}>
      </td>
      <td>
        <input class='input' type='text' name='list[list_people][][role]' id='list_list_people_role'>
      </td>
      <td>
        <a data-action='remove-list-person' data-person-id=#{personId}>
          <i class='far fa-times' aria-hidden='true'></i>
        </a>
      </td>
    </tr>"

  $('#list-people #list-people-list').append(newRow)
  $('#list-people').find('.list-group-item:last').find("a[data-action='remove-list-person']").on('click', removeListPersonRow)

  updateCount(parseInt($('#list-member-count').text()) + 1)

  $("##{personId} a[data-action='remove-list-person']").on('click', removeListPersonRow)

addListPersonClick = ->
  $input = $('#list-person-autocomplete')

  addListPersonRow($input.data('person-id'), $input.data('person-full-name'))

  $input.removeData('person-id')
  $input.removeData('person-full-name')

  $('#list-person-autocomplete').val('')
  $("button[data-action='add-list-person']").off('click')

emptyErrors = ->
  $('#list-people .input-group').removeClass('form-error')
  $('#list_list_people_person_error').removeClass('form-error-message')
  $('#list_list_people_person_error').empty()

listPeople = ->
  $('#list-people-list td input[type=hidden]').map -> @value

validateListPerson = ->
  $input = $('#list-person-autocomplete')

  personId = $input.data('person-id')
  personFullName = $input.data('person-full-name')

  $("button[data-action='add-list-person']").off('click')

  return false unless (personId)

  $error = $('#list-people #list_list_people_person_error')
  if (listPeople().toArray().includes(personId.toString()))
    $error.addClass('input-help-text form-error-message')
    $error.append("#{personFullName} has already been added as a member of this list.")
    $('#list-people .input-group').addClass('form-error')
    false
  else
    $("button[data-action='add-list-person']").on 'click', addListPersonClick
    true

validateForm = ->
  emptyErrors()
  validateListPerson()

$.onmount '#list-people', ->
  $(@).on 'autocomplete:inputChanged', (obj) ->
    $input = $('#list-person-autocomplete')
    inputValue = $input.val()
    personFullName = $input.data('person-full-name')

    if (inputValue != personFullName)
      $input.removeData('person-id')
      $input.removeData('person-full-name')

      validateForm()

  $(@).on 'autocomplete:selectSuggestion', (obj, $selected) ->
    personId = $selected.data('id')
    personFullName = $selected.find('.list-group-item-title').text().trim()
    $input = $('#list-person-autocomplete')

    $input.data('person-id', personId)
    $input.data('person-full-name', personFullName)

    validateForm()

  $("a[data-action='remove-list-person']").on('click', removeListPersonRow)

  $(@).on 'keydown', (e) ->
    if Keyboard.keyMatchesName(e.which, 'enter')
      e.preventDefault()
      addListPersonClick() if validateListPerson()
      validateForm()

$.onmount 'form.new_list', ->
  $('form.new_list input[type=submit]').on 'click', (e) ->
    e.preventDefault() if $('form.new_list .form-error-message.ui-error').length

    if window.location.pathname.indexOf($('form.new_list').attr('action')) > 0
      $('[data-pjax-container=lists]').one 'pjax:end', ->
        App.OrgChart.getChart().reloadData()
