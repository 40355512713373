$.onmount '#edit_settings', ->
  functions = {
    entryMethodChanged: ->
      entryMethod = $(@).find('option:selected').val()
      $('[data-hide=entry-method]').hide()

      switch (entryMethod)
        when 'manual' then $('[data-show=entry-method-manual]').show()
        when 'timer' then $('[data-show=entry-method-timer]').show()
    toggleChartVisibility: ->
      if ($(this).val() == 'manager')
        $('#chart-select').slideDown('normal')
      else
        $('#chart-select').slideUp('normal')
  }

  $('#company_settings_timesheets_entry_method').on 'change', functions.entryMethodChanged
  $('[name="company[settings][timesheets][approver_type]"]').on 'change', functions.toggleChartVisibility
