import throttle from "lodash/throttle"
import UploadForm from "./csv_import/upload_form"

const $ = window.jQuery
const progressBarSelector = "[data-pjax-container=progress]"
const $progressBarContainer = $(progressBarSelector)
const pjaxUrl = $progressBarContainer.data("pjax-url")

let stopPoll = false
const pollForProgress = throttle(() => {
  if (stopPoll) return

  $.pjax({
    url: pjaxUrl,
    container: progressBarSelector,
    push: false,
    scrollTo: false,
  })
}, 1000)

const redirectOrCheckAgain = (e, response) => {
  const $progressElement = $(e.currentTarget).find("[data-progress]")
  const percentComplete = $progressElement.data("progress")
  const shouldRedirect = $progressElement.data("redirect")

  // Check for the X-PJAX-IMPORT-DONE header, which the server uses to indicate
  // that the response has changed X-PJAX-URL. PJAX cancels active requests if
  // we initiate a new request, and that includes redirects. This can lead to an
  // awkward loop of:
  //   Request -> Start Redirect -> Poll (Canceling Redirect) -> ...
  // Hence the use of X-PJAX-IMPORT-DONE to short-circuit this behavior.
  const headerPjaxImportDone = response.getResponseHeader("X-PJAX-IMPORT-DONE")
  if (headerPjaxImportDone === "true") {
    stopPoll = true
    return
  } else if (percentComplete === 100 && shouldRedirect) {
    stopPoll = true
    const redirectUrl = $progressBarContainer.data("redirect-url")
    window.location = redirectUrl
    return
  }

  pollForProgress()
}

const disableNprogressBar = () => {
  $(document).off("pjax:send.nprogress")
  $(document).off("pjax:complete.nprogress")
}

const startProgressBar = () => {
  disableNprogressBar()
  pollForProgress()
  $progressBarContainer.on("pjax:complete", redirectOrCheckAgain)
}

$.onmount("#import-processing-page", startProgressBar)

function toggleAll() {
  $(".duplicate-people input[type=checkbox]").prop("checked", $(this).is(":checked"))
}

$.onmount("#overwrite_all", function handleOverwriteAll() {
  $(this).on("click", toggleAll)
})

$.onmount("#upload-importable-csv-form", UploadForm.mount)

const modifySubmitButtonState = (e) => {
  const $submitButton = $(e.currentTarget).find("[type=submit]")
  const $secondarySubmits = $(`[form=${e.currentTarget.id}]`)
  const $submits = $submitButton.add($secondarySubmits)

  $.each($submits, (index, submit) => {
    const $submit = $(submit)
    const disabledText = $submit.data("disabled-value")
    $submit.attr("disabled", true)
    $submit.attr("value", disabledText)
  })
}

$.onmount("form#map-headers, form#import-form", function formSubmitHandler() {
  $(this).on("submit", modifySubmitButtonState)
})

// the html5 form attribute does not work in ie11, so we need to manually
// submit the form here.
const submitImportForm = (e) => {
  const formId = e.currentTarget.attributes.form.value
  $(`#${formId}`).submit()
}

$.onmount("[form=import-form]", function formClickHandler() {
  $(this).on("click", submitImportForm)
})
