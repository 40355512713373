$.onmount '.permissions-person', ->
  $(@).on 'autocomplete:selectSuggestion', (obj, $selected) ->
    personId = $selected.data('id')
    personFullName = $selected.find('.list-group-item-title').text().trim()

    $input = $('#person_name')

    $input.data('person-id', personId)
    $input.data('person-full-name', personFullName)

    $('#person_id').val(personId)

  $(@).on 'autocomplete:inputChanged', (e) ->
    $input = $('#person_name')
    inputValue = $input.val()
    personFullName = $input.data('person-full-name')

    if (inputValue != personFullName)
      $('#person_id').val('')

      $input.removeData('person-id')
      $input.removeData('person-full-name')
