$.onmount Modal.selector('pdf-field'), ->
  $modal = $(@)

  $modal.find('#pdf-field-person-attribute').on 'change', (e) ->
    $modal.find('[data-action=close] svg').trigger 'click'

  $modal.on 'modal:close', (_e, _modal) ->
    personAttribute = $modal.find('option:selected').val()
    $form = $("##{$modal.find('#pdf-field-person-attribute').data('form-id')}")
    $form.find('#pdf_field_person_attribute').val(personAttribute)
    $form.trigger 'submit'
