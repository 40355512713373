removeListPersonRow = (e) ->
  $(e.currentTarget).parents('.list-group-item').remove()
  updateExcludeList()

addPositionTypeRow = (e, $suggestion) ->
  newRow = "
    <div class='list-group-item' data-id='#{$suggestion.data('id')}'>
      #{$suggestion.text()}
      <input type='hidden' name='custom_field[position_type_ids][]' value='#{$suggestion.data('id')}'>
      <a href='#' class='remove' data-action='remove-position-type-row'><i class='far fa-times'></i></a>
    </div>"

  $('#position-types-list').append(newRow)
  $('#position-types-list').find('.list-group-item:last').find("a[data-action='remove-position-type-row']").on('click', removeListPersonRow)
  $('[name=position_type]').val('')
  updateExcludeList()

updateExcludeList = ->
  positionTypeIds = $('#position-types-list .list-group-item').map( -> $(this).data('id')).get()
  $('[name=position_type]').data('exclude', positionTypeIds)

$.onmount "form[id*='new_custom_field']", ->
  $isRequiredInput = $('[name="custom_field[is_required]"')
  $requiredForForm = $('#required-for')
  $requiredList = $('#required-positions-list')

  $isRequiredInput.on 'change', ->
    if (this.value == 'true')
      $requiredForForm.removeClass('hidden')
    else
      $requiredForForm.addClass('hidden')

  $requiredForForm.find("input[type=radio][name='custom_field[required_for]']").on 'change', ->
    if (this.value == 'positions')
      $requiredList.removeClass('hidden')
    else
      $requiredList.addClass('hidden')

  $('[name=position_type]').on 'autocomplete:selectSuggestion', addPositionTypeRow
  $('#position-types-list a[data-action=remove-position-type-row]').on('click', removeListPersonRow)

