# Search Autocomplete
$('.search .input').on 'autocomplete:selectSuggestion', (obj, $selected) ->
  document.location.href = '/people/' + $selected.data('id')

$.onmount '.nav-search', ->
  $(@).click ->
    navShrunk = $('.nav').hasClass('shrink-nav')
    if navShrunk
      # fires logic handled in NavToggle.tsx
      $('.nav-toggle').click()
      $('.nav-search .input').focus()

# Timer
$.onmount '[data-pjax-container=timer-button]', ->
  options = {
    url: $(this).data('pjax-render'),
    container: '[data-pjax-container=timer-button]',
    push: false,
    scrollTo: false,
  }

  setInterval ->
    $.pjax(options)
  , 30000
