jQuery ($) ->
  class LocationsMap
    constructor: (@el) ->
      @$el = $(@el)
      @loadMap = @loadMap.bind(@)

    loadMap: (@locations) ->
      return unless window.google

      # Default center to zoomed out view on US
      properties =
        zoom: 15
        maxZoom: 18
        center: new google.maps.LatLng(38.8833, -100.0)

      @markerBounds = new google.maps.LatLngBounds()

      initial = @getLatLng _.first(@locations)
      if initial
        properties['center'] = initial
      else
        @markerBounds.extend properties['center']

      @map = new google.maps.Map @$el.get(0), properties

      _.each @locations, (location) => @setMarker location
      @map.fitBounds(@markerBounds) if @locations.length > 1

    getLatLng: (location, center = false) ->
      return unless location
      lat = location.latitude
      lng = location.longitude

      return unless lat && lng

      point = new google.maps.LatLng parseFloat(lat), parseFloat(lng)
      @markerBounds.extend point
      @map.setCenter(point) if center

      point

    setMarker: (location) ->
      img = 'red-dot.png'
      img = 'green-dot.png' if location.default
      img = "//maps.google.com/mapfiles/ms/icons/#{img}"

      label = location.label
      position = @getLatLng location, location.default

      return unless position

      new google.maps.Marker
        icon: img
        map: @map
        position: position
        title: label

  $.onmount '[data-locations-map]', ->
    return unless window.google
    locationsMap = new LocationsMap @
    return unless (companyId = $(@).data('companyId'))
    url = "/api/app/v1/companies/#{companyId}/locations.json"

    loadMap = -> $.getJSON url, locationsMap.loadMap
    loadMap()
