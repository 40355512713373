$.onmount '#required-fields input[type="checkbox"]', ->
  $(@).on 'click', (e) ->
    $dispatchEl = $(@).closest('[data-pjax-url]')
    containerName = $dispatchEl.data('pjax-selector')
    url = $dispatchEl.data('pjax-url')
    selector = Pjax.targetContainer(containerName)
    options = { container: selector, url: url , scrollTo: false }
    if $dispatchEl.data('pjax-push') == false
      options.push = false
    options.type = $dispatchEl.data('pjax-type') if $dispatchEl[0].hasAttribute('data-pjax-type')
    options.fragment = selector if $dispatchEl[0].hasAttribute('data-pjax-fragment')

    $.pjax(options)
    $.pjax.xhr = null
