$.onmount '.with-hidden-options', ->
  $container = $(@)

  functions = {
    updateRadio: ->
      $moreOptions = $(@).closest('label.radio').next()
      checked = $(@)[0].checked

      $container.children('.card').slideUp()

      $moreOptions.slideDown() if checked

    updateCheckbox: ->
      $moreOptions = $(@).closest('label.checkbox').next()
      checked = $(@)[0].checked

      $container.children('.card').slideUp()

      $moreOptions.slideDown() if checked

    setRadio: ->
      $moreOptions = $(@).closest('label.radio').next()
      checked = $(@)[0].checked

      $container.children('.card').hide()

      $moreOptions.show() if checked

    setCheckbox: ->
      $moreOptions = $(@).closest('label.checkbox').next()
      checked = $(@)[0].checked

      $container.children('.card').hide()

      $moreOptions.show() if checked
  }

  $container.children('label.radio').children('input[type=radio]:checked').each functions.setRadio
  $container.children('label.radio').children('input[type=radio]').on 'change', functions.updateRadio

  $container.children('label.checkbox').children('input[type=checkbox]:checked').each functions.setCheckbox
  $container.children('label.checkbox').children('input[type=checkbox]').on 'change', functions.updateCheckbox
