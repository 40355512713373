$.onmount Modal.selector('person-set-default-email'), ->
  constants = {
    $modal: $(@),
    $provideEmail: $(@).find('button[data-action=provide-email]'),
    $provideEmailSelect: $(@).find('#provide-email-select'),
    $saveBtn: $(@).find('[data-action=save]'),
    $emailValue: ->
      constants.$modal.find(
        "[name='person[work_email]'], [name='person[personal_email]']"
      )
  }

  functions = {
    emailTypeChanged: ->
      selectedInput = $(this).val()
      inputName = "person[#{selectedInput}_email]"
      inputId = "person_#{selectedInput}_email"
      constants.$emailValue().attr('name', inputName).attr('id', inputId)

    provideEmailClick: ->
      constants.$provideEmailSelect.removeClass('hidden')
      constants.$provideEmail.remove()
      constants.$emailValue().focus()
      constants.$saveBtn.removeClass('disabled')
      constants.$saveBtn.attr('disabled', false)

    modalSave: (_e, modal) ->
      functions.loadNextModal(modal)
      functions.reloadProfileHeader(modal)
      functions.reloadPersonContactInfo()

    loadNextModal: (modal) ->
      $.pjax(
        url: modal.data.modalAfterUrl,
        container: Pjax.targetContainer('modal'),
        push: false
        scrollTo: false
      )
      $.pjax.xhr = null

    reloadProfileHeader: (modal) ->
      Profile.reloadProfileHeader(modal.$form)
      $.pjax.xhr = null

    reloadPersonContactInfo: ->
      containerSelector = Pjax.targetContainer('person-work-contact-info')
      if $(containerSelector).length == 0
        containerSelector = Pjax.targetContainer('person-contact-info')

      if $(containerSelector).length > 0
        $.pjax.reload(containerSelector, fragment: containerSelector)
        $.pjax.xhr = null
  }

  $(@).on 'change', "[name='person[email_type]']", functions.emailTypeChanged
  $(@).on 'click', '[data-action=provide-email]', functions.provideEmailClick
  $(@).on 'modal:save', functions.modalSave
