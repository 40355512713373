Preview = window.Preview

constants = {
  elementSizes: {
    text: {
      height: 20,
      width: 200,
    },
    signature: {
      height: 30,
      width: 250,
    },
    date: {
      height: 20,
      width: 100,
    },
    checkbox: {
      height: 15,
      width: 15,
    },
  }
}

switchToText = (e) ->
  e.preventDefault() if e
  $('#new_pdf_field #pdf_field_field_type').val('text')
  $('#new_pdf_field #pdf_field_person_attribute').val('')
  $('canvas').css('cursor', 'crosshair')
  $('.active').removeClass('active')
  $('[data-action=text]').addClass('active')

switchToSignature = (e) ->
  e.preventDefault() if e
  $('#new_pdf_field #pdf_field_field_type').val('signature')
  $('#new_pdf_field #pdf_field_person_attribute').val('')
  $('canvas').css('cursor', 'crosshair')
  $('.active').removeClass('active')
  $('[data-action=signature]').addClass('active')

switchToDate = (e) ->
  e.preventDefault() if e
  $('#new_pdf_field #pdf_field_field_type').val('date')
  $('#new_pdf_field #pdf_field_person_attribute').val('')
  $('canvas').css('cursor', 'crosshair')
  $('.active').removeClass('active')
  $('[data-action=date]').addClass('active')

switchToCheckbox = (e) ->
  e.preventDefault() if e
  $('#new_pdf_field #pdf_field_field_type').val('checkbox')
  $('#new_pdf_field #pdf_field_person_attribute').val('')
  $('canvas').css('cursor', 'crosshair')
  $('.active').removeClass('active')
  $('[data-action=checkbox]').addClass('active')

renderFields = (page) ->
  if $("#{page} .form-field").length
    $.each $("#{page} .form-field"), (_i, field ) ->
      $field = $(field)
      viewport = $(@).closest('.page').data('viewport')
      vpPosition = viewport.convertToViewportPoint $field.data('left'), $field.data('top')

      $field.css({
        width: parseFloat($field.data('width')) * viewport.scale + 'px',
        height: parseFloat($field.data('height')) * viewport.scale + 'px',
        left: vpPosition[0],
        top: vpPosition[1],
      })

      $field.draggable({
        containment: $field.closest('.page')[0],
        stop: (event, ui) =>
          pdfPosition = viewport.convertToPdfPoint ui.position.left, ui.position.top
          $(event.target).find('#pdf_field_position_x').val(pdfPosition[0])
          $(event.target).find('#pdf_field_position_y').val(pdfPosition[1])
          $(event.target).find('form').trigger 'submit'
      })

      $field.not('.checkbox').resizable({
        containment: $field.closest('.page')[0]
        stop: (event, ui) ->
          $(event.target).find('#pdf_field_width').val(ui.size.width / viewport.scale)
          $(event.target).find('#pdf_field_height').val(ui.size.height / viewport.scale)
          $(event.target).find('form').trigger 'submit'
      })

$.onmount Preview.selector('company-documents-edit'), ->
  $(@).on 'preview:renderPage', (_e, _preview, page) ->
    $('[data-action=checkbox]').on 'click', switchToCheckbox
    $('[data-action=signature]').on 'click', switchToSignature
    $('[data-action=date]').on 'click', switchToDate
    $('[data-action=text]').on 'click', switchToText
    $('[data-action=text]').trigger 'click'

    $page = $('.page' + page.pageNumber)

    $page.on 'click', (e) ->
      if $(e.target).is('canvas')
        viewport = $(@).closest('.page').data('viewport')
        pdfPosition = viewport.convertToPdfPoint e.offsetX, e.offsetY
        sizes = constants.elementSizes[$('#new_pdf_field #pdf_field_field_type').val()]
        $form = $('#new_pdf_field')
        $form.find('#pdf_field_position_x').val(pdfPosition[0])
        $form.find('#pdf_field_position_y').val(pdfPosition[1])
        $form.find('#pdf_field_height').val(sizes.height / viewport.scale)
        $form.find('#pdf_field_width').val(sizes.width / viewport.scale)
        $form.find('#pdf_field_page').val(page.pageNumber)
        $form.data('pjax-submit', "pdf_fields_#{page.pageNumber}")
        editable = $form.data('editable')
        fieldType = $form.find('#pdf_field_field_type').val()

        if (editable.includes(fieldType))
          $('#new-form-link').trigger 'click'
        else
          $('#new_pdf_field').trigger 'submit'

    renderFields('.page' + page.pageNumber)

    $("[data-pjax-container=pdf_fields_#{page.pageNumber}]").parent().on 'pjax:end', =>
      renderFields('.page' + page.pageNumber)
