$.onmount 'form.new_pto_type', ->
  functions = {
    selectAll: ->
      $icon = $(('[data-action="toggle-visibility"] svg'))
      $iconCheckbox = $('[name$=\\[visible\\]]')
      $checkbox = $('[name$=\\[exists\\]]')

      if ($('[data-action="select-all"]')[0].checked)
        $checkbox.prop('checked', true)
        $iconCheckbox.prop('checked', true)
        $icon.addClass('fa-eye').removeClass('disabled')
      else
        $checkbox.prop('checked', false)
        $iconCheckbox.prop('checked', false)
        $icon.addClass('fa-eye-slash disabled')

    toggleVisibility: ->
      $listItem = $(@).closest('.list-group-item')
      $icon = $listItem.find('[data-action="toggle-visibility"] svg')
      $iconCheckbox = $listItem.find('[name$=\\[visible\\]]')
      $checkbox = $listItem.find('[name$=\\[exists\\]]')

      if ($checkbox[0].checked)
        if ($icon.hasClass('disabled'))
          $iconCheckbox.prop('checked', true)
          $icon.addClass('fa-eye').removeClass('disabled')
        else
          $iconCheckbox.prop('checked', false)
          $icon.addClass('fa-eye-slash disabled')
      else
        $iconCheckbox.prop('checked', false)
        $icon.addClass('fa-eye-slash disabled')

    allVisible: ->
      $checkbox = $('[name$=\\[exists\\]]:checked')
      $listItem = $checkbox.closest('.list-group-item')
      $icon = $listItem.find(('[data-action="toggle-visibility"] svg'))
      $iconCheckbox = $listItem.find('[name$=\\[visible\\]]')

      $iconCheckbox.prop('checked', true)
      $icon.addClass('fa-eye').removeClass('disabled')

    noneVisible: ->
      $icon = $(('[data-action="toggle-visibility"] svg'))
      $iconCheckbox = $('[name$=\\[visible\\]]')

      $iconCheckbox.prop('checked', false)
      $icon.addClass('fa-eye-slash disabled')
  }

  $(@).find('[data-action="select-all"]').on('change', functions.selectAll)
  $(@).find('[data-action="toggle-visibility"]').on('click', functions.toggleVisibility)
  $(@).find('[name$="\\[exists\\]"]').on('change', functions.toggleVisibility)
  $(@).find('[data-action="all-visible"]').on('click', functions.allVisible)
  $(@).find('[data-action="none-visible"]').on('click', functions.noneVisible)
