$.onmount "form[id*='new_chart_section']", ->
  $additionalForm = $('#add_new_head_form')

  showForm = ->
    $additionalForm.show()
    $('#chart_section_new_head').val('true')
    $('#chart_section_position_id').val('')

  hideForm = ->
    $additionalForm.hide()
    $('#chart_section_new_head').val('false')
    $('#chart_section_position_position_type_id').val('')
    $('#chart_section_position_position_type_title').val('')
    $('#chart_section_position_parent').val('')
    $('#chart_section_position_parent_id').val('')

  $(@).on 'autocomplete:selectSuggestion', (obj, $selected) ->
    receivedId = $selected.data('id')
    autocompleteInputNameId = $(obj.target).data('id')
    $("##{autocompleteInputNameId}").val(receivedId)
    hideForm() if autocompleteInputNameId == 'chart_section_position_id'

  $(@).on 'autocomplete:renderSuggestions', ->
    $('#show-add-new-position-form').mousedown showForm

    $('#hide-add-new-position-form').mousedown ->
      $('#chart_section_position_name').val('')
      hideForm()

  $('#chart_section_chart_id').change ->
    $('#chart_section_position_name').val('').data('chart-id', $(this).val())
    $('#chart_section_position_id').val('')
    $('#chart_section_position_parent').val('').data('chart-id', $(this).val())
    $('#chart_section_position_parent_id').val('')
