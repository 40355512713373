$.onmount '[data-uploader=person-avatar]', ->
  $(@).on 'image:file:error', (_e, _uploader) ->
    alert 'An error occurred. Please select your avatar again.'

  $(@).on 'image:upload:error', (_e, _uploader, _xhr, _status, _error) ->
    alert 'An error occurred. Please select your avatar again.'

  $(@).on 'image:upload:success', (_e, _uploader, data, status, xhr) ->
    logoUrl = data[_uploader.imageUrlKey]
    $('img[data-person-avatar]').attr('src', logoUrl)
