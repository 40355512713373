class Autocomplete
  constructor: (input) ->
    @$input = $(input)
    @$input.data('autocomplete-instance', @)
    @autocompleteClass = @$input.data('autocomplete-wrapper-class') || ''
    @currentQuery = input.value
    @remoteURL = @$input.data('autocomplete-url')
    @cachedResponse = {}
    @suggestionsContainer = $("
      <div
        class=\"autocomplete-container #{@autocompleteClass}\"
        data-autocomplete-container=\"#{@$input.data('autocomplete')}\">
      </div>
    ")
    @$input.parent().append @suggestionsContainer
    input.setAttribute('autocomplete', 'off')
    @$input.on @inputEvents()

    @$searchFilters = @$input.siblings('.search-filters')
    @filterOptionsPresent = @$searchFilters.length > 0
    if @filterOptionsPresent
      @$searchFilters.find('a').on 'click', (e) => @handleFilterClick(e)

    @$input.siblings('[data-action=clear-search]').on 'click', (e) => @clearSearch(e)
    @preloadSuggestions = @$input.data('preload-suggestions') == true

  inputEvents: ->
    focus: => @focusInput()
    blur: => @blurInput()
    keydown: (e) =>
      key = e.which
      switch
        when Keyboard.keyMatchesName(key, 'enter') then @onEnter(e)
        when Keyboard.keyMatchesName(key, 'esc') then @blurInput()
        when Keyboard.keyMatchesName(key, 'up') then @highlightPrevious()
        when Keyboard.keyMatchesName(key, 'down') then @highlightNext()
        when Keyboard.keyMatchesName(key, 'tab') then @onTabDown(e)
    keyup: (e) =>
      key = e.which
      switch
        when Keyboard.keyMatchesName(key, 'enter') then return
        when Keyboard.keyMatchesName(key, 'up') then return
        when Keyboard.keyMatchesName(key, 'down') then return
        when Keyboard.keyMatchesName(key, 'left') then return
        when Keyboard.keyMatchesName(key, 'right') then return
        when Keyboard.keyMatchesName(key, 'tab') then return
        when Keyboard.keyMatchesName(key, 'shift') then return
        else @inputChanged()

  blurInput: (e) ->
    setTimeout( =>
      if @cancelBlur
        @cancelBlur = false
      else
        @hide()
    , 300)

  focusInput: (e) ->
    if @preloadSuggestions && @$input.val() == ''
      @getSuggestions()
    else
      @getSuggestions() unless @$input.val() in ['', @currentQuery]

    @showFilterOptions(e) if @filterOptionsPresent

  onEnter: (e) ->
    e.preventDefault()
    @getSuggestions() if window.rate_limit_search
    return if @$highlighted && @$highlighted.data('autocompleteIgnore')
    if @$highlighted && @$highlighted.length > 0 && !@selected
      @selectSuggestion(@$highlighted, e)

  highlightPrevious: ->
    @$highlighted.removeClass('highlight') if @$highlighted

    if !@$highlighted || @$highlighted.is(':first-child')
      @$highlighted = @suggestionsList.children(':not(.disabled)').last()
    else
      @$highlighted = @$highlighted.prev(':not(.disabled)')

    @$highlighted.addClass('highlight')

  highlightNext: ->
    @$highlighted.removeClass('highlight') if @$highlighted

    if !@$highlighted || @$highlighted.is(':last-child')
      @$highlighted = @suggestionsList.children(':not(.empty-message)').first()
    else
      @$highlighted = @$highlighted.next(':not(.disabled)')

    @$highlighted.addClass('highlight')

  onTabDown: (e) ->
    unless e.shiftKey
      e.preventDefault()
      return if @$highlighted && @$highlighted.data('autocompleteIgnore')

      if @$highlighted? && @$highlighted.length > 0
        @selectSuggestion(@$highlighted, e)
      else
        field = $(e.currentTarget)
        @selectNextField(field)

  selectNextField: (field) ->
    $visible_form_controls = $(':input:visible:not(.skip-tab)')
    index = $visible_form_controls.index(field)
    $next = $visible_form_controls.get(index + 1)
    $next.focus() if $next

  inputChanged: _.debounce(
    (e) ->
      @selected = @$highlighted = null

      @$input.parent().find('img.prefix').remove()

      if @$input.val().length == 0 && !@preloadSuggestions
        @hide()
        @$input.trigger('autocomplete:clearSelected')
      else
        @getSuggestions()
      @$input.trigger('autocomplete:inputChanged')

      if @filterOptionsPresent && @$input.val().length == 0
        @showFilterOptions()
      else if @filterOptionsPresent
        @hideFilterOptions()

      @toggleX()
    , (if window.gon?.rate_limit_search then 1000 else 250)
  )

  selectSuggestion: ($suggestion, evt) ->
    @selected = $suggestion
    return if @selected.hasClass('disabled')
    if @selected?
      displayText = $suggestion.find('.list-group-item-title').text().trim() || $suggestion.text().trim()
      if displayText
        @$input.val(displayText)
        @currentQuery = @$input.val()

      receivedId = $suggestion.data('id')
      autocompleteInputNameId = @$input.data('id')
      $("##{autocompleteInputNameId}").val(receivedId)
      $("##{autocompleteInputNameId}").trigger('change')

      @$input.trigger('autocomplete:selectSuggestion', [$suggestion, evt])

    @hide()
    @suggestionsContainer.empty()

  getSuggestions: ->
    @currentQuery = @$input.val()
    q = encodeURIComponent(@currentQuery)

    remoteURL = @remoteURL
    if remoteURL.indexOf('?') > 0
      cacheKey = remoteURL + '&query=' + q
    else
      cacheKey = remoteURL + '?query=' + q

    exclude = encodeURIComponent(@$input.data('exclude'))
    cacheKey += "&exclude=#{exclude}" if exclude && exclude != 'undefined'

    job_id = encodeURIComponent(@$input.data('job-id'))
    cacheKey += "&job_id=#{job_id}" if job_id != 'undefined'

    chart_id = encodeURIComponent(@$input.data('chart-id'))
    cacheKey += "&chart_id=#{chart_id}" if chart_id != 'undefined'

    autocompleteParams = @$input.data('autocomplete-params')
    if autocompleteParams
      cacheKey += "&#{key}=#{val}" for key, val of autocompleteParams

    response = @cachedResponse[cacheKey]

    if response
      @suggestions = response
      @renderSuggestions()
    else
      $.ajax
        url: cacheKey
        type: 'GET'
        contentType: 'application/json'
      .done (data) =>
        @suggestions = data
        @cachedResponse[cacheKey] = data
        @renderSuggestions()

  renderSuggestions: ->
    return unless @$input.is(':focus')

    @suggestionsContainer.empty()

    @suggestionsContainer.append(@suggestions)
    @suggestionsList = @suggestionsContainer.children('.autocomplete-list')
    @highlightNext()
    @suggestionsContainer
      .find('.list-group-item')
      .mousedown(@, @clickSuggestion)

    @suggestionsContainer.show()
    @$input.trigger('autocomplete:renderSuggestions')
    @focusInput() unless @$input.val() == '' && @preloadSuggestions

  clickSuggestion: (e) =>
    e.preventDefault()
    e.stopPropagation()
    @selectSuggestion($(e.currentTarget), e)

  hide: ->
    @$highlighted = null
    @suggestionsContainer.hide()
    @hideFilterOptions() if @filterOptionsPresent

  showFilterOptions: (e) ->
    return if @$input.val() != ''
    @$searchFilters.show()

  hideFilterOptions: (e) ->
    return if e && $(e.relatedTarget).parent().hasClass('search-filters')

    @$searchFilters.hide()
    @$searchFilters.find('.active')
      .removeClass('active')
    @$searchFilters.find('a').first()
      .addClass('active')

  handleFilterClick: (e) ->
    e.preventDefault()
    @cancelBlur = true
    @$input.focus()

    $clicked = $(e.currentTarget)

    if @$input.val() == '' && $clicked.text() != 'Everything'.t('org_chart')
      @$input.val($clicked.data('filter-text'))

    @hideFilterOptions()
    @toggleX()

  clearSearch: (e) ->
    e.preventDefault()

    @cancelBlur = true
    @$input.val('')
    @$input.focus()
    @hide()
    @showFilterOptions(e)
    @toggleX()

  toggleX: ->
    if @$input.val() == ''
      @$input.siblings('.suffix').css('display', 'none')
    else
      @$input.siblings('.suffix').css('display', 'flex')

$.onmount '[data-autocomplete]', -> new Autocomplete(@)
