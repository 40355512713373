class ImageUploader
  constructor: (el) ->
    @$el = $(el)
    @$image = @$el.find('img')
    @$imageAction = @$el.find('[data-action=edit-image]')
    @$form = @$el.find('form')
    # form will be present only if the person has the ability to edit the image
    return unless !!@$form[0]
    @$fileInputField = @$form.find('[type=file]')
    @$loadingIndicator = @$el.find('.image-uploader-loading-indicator')

    @modalUrl = @$el.data('uploader-modal-url')
    @imageUrlKey = @$el.data('uploader-image-url-key')
    unless @modalUrl && @imageUrlKey
      throw new Error('data attributes missing for image uploader')

    @$imageAction.click @onActionClick
    @$fileInputField.change @onFileChange

  onActionClick: =>
    @$fileInputField.click()

  onFileChange: (e) =>
    if !@$fileInputField.val()
      e.preventDefault()
      @fileError()
    else
      @openModal()

  fileError: =>
    @$form[0].reset()
    Sentry?.captureMessage 'Trying to upload an image without an actual file.'
    @$el.trigger 'image:file:error', [@]

  openModal: =>
    $.pjax(
      url: @modalUrl
      container: '[data-pjax-container=modal]'
      push: false
      scrollTo: false
    ).then =>
      $("[data-modal-image]").on 'image:set', @upload

  upload: (_e, blob, dataURL) =>
    formData = new FormData()
    name = @$fileInputField.val().split(/(\\|\/)/g).pop() || 'blob.png'
    formData.append(@$fileInputField.attr('name'), blob, name)

    if @$el.data('uploader-save') == false
      @$image.attr('src', dataURL)
      return

    @$loadingIndicator.show()
    $.ajax
      type: 'PUT'
      url: @$form.attr('action')
      data: formData
      processData: false
      contentType: false
      error: @uploadError
      success: @uploadSuccess

  uploadError: (xhr, status, error) =>
    @$form[0].reset()
    Sentry?.captureMessage 'Image failed to be posted to the server'
    @$el.trigger 'image:upload:error', [@, xhr, status, error]

  uploadSuccess: (data, status, xhr) =>
    @$image.load(() => @$loadingIndicator.hide())
    @$image.attr('src', data[@imageUrlKey])
    @$form[0].reset()
    @$el.trigger 'image:upload:success', [@, data, status, xhr]

$.onmount '[data-uploader]', -> new ImageUploader(@)
