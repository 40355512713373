# Read more toggle
$.onmount '.read-more', ->
  $(@).readmore
    collapsedHeight: 75
    moreLink: '<a href="#">Show more</a>'
    lessLink: '<a href="#">Show less</a>'
    afterToggle: (trigger, element, expanded) ->
      if !expanded
        # The "Close" link was clicked
        element.toggleClass 'read-more-ellipsis'
      return
    beforeToggle: (trigger, element, expanded) ->
      if expanded
        element.addClass 'read-more-ellipsis'
      else
        element.removeClass 'read-more-ellipsis'
      return
    blockProcessed: (element, collapsible) ->
      if collapsible
        element.addClass 'read-more-ellipsis'
      return
