const { jQuery: $, Profile, Modal, Pjax } = window

$.onmount(Modal.selector("person-update-status"), function () {
  $(this).on("modal:save", (_e, modal) => {
    Profile.reloadProfileHeader(modal.$form).then(() => {
      const containerSelector = Pjax.targetContainer("person-job-tab")
      if (containerSelector) {
        $.pjax.reload(containerSelector, { fragment: containerSelector })
      }
    })
  })
})
