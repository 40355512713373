import moment from "moment"

const $ = window.jQuery

$.tablesorter.addParser({
  id: "numericWithUnit",
  is(s) {
    return /^\s*(?!\d+[/-]\d*)[-+]?\d+\.?\d*\s*(?:\w+)?/.test(s)
  },
  format(s) {
    let value = s.match(/^\s*([-+]?\d+\.?\d*)\s*(?:\w+)?/)
    value = value ? value[1] : s
    return $.tablesorter.formatFloat(value)
  },
  type: "numeric",
})

// This also works for date range. It just compares on start date
$.tablesorter.addParser({
  id: "date",
  is(s) {
    const dates = s.split(" – ")
    return dates.length > 1
      ? moment(dates[0], "MMM D", true).isValid() || moment(dates[0], "MMM D, YYYY", true).isValid()
      : moment(dates[0], "MMM D, YYYY", true).isValid()
  },
  format(s) {
    const dates = s.split(" – ")

    const startDateShort = moment(dates[0], "MMM D", true)
    const startDateLong = moment(dates[0], "MMM D, YYYY", true)
    const endDate = dates[1] ? moment(dates[1], "MMM D, YYYY", true) : moment()

    return startDateLong.isValid()
      ? startDateLong.unix()
      : startDateShort.year(endDate.year()).unix()
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "birthdateMonth",
  is(s) {
    return moment(s, "MMMM D", true).isValid()
  },
  format(s) {
    return moment(s, "MMMM D").unix() || ""
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "dateTime",
  is(s) {
    return moment(s, "MM/DD/YYYY HH:mm:ss a", true).isValid()
  },
  format(s) {
    return moment(s, "MM/DD/YYYY HH:mm:ss a").unix() || ""
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "weekDateTime",
  is(s) {
    return moment(s, "ddd, DD MMM YYYY HH:mm:ss Z", true).isValid()
  },
  format(s) {
    return moment(s, "ddd, DD MMM YYYY HH:mm:ss Z").unix() || ""
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "dateAtTime",
  is(s) {
    return s.indexOf(" at ") > 0 && moment(s, "MMM DD, YYYY at hh:mm a", true).isValid()
  },
  format(s) {
    return moment(s, "MMM DD, YYYY at hh:mm a").unix() || ""
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "currency",
  is(s) {
    return /^[£$€]{1}/.test(s)
  },
  format(s) {
    return $.tablesorter.formatFloat(s.replace(new RegExp(/[£$€\\,]/g), ""))
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "email",
  is(s) {
    return s.includes("@")
  },
  format(s) {
    return s.replace("@", "")
  },
  type: "text",
})

$.tablesorter.addParser({
  id: "phone",
  is(s) {
    return s.includes("(")
  },
  format(s) {
    return s.replace(new RegExp(/[()\- x.]/g), "")
  },
  type: "text",
})

$.tablesorter.addParser({
  id: "importance",
  is(s, table, cell) {
    if (!cell) {
      return false
    }

    let $cell
    if (cell.children.length > 0) {
      $cell = $(cell.innerHTML)
    } else {
      $cell = $(cell)
    }

    if ($cell.data("sort-parser") === "importance") {
      return true
    }
    return false
  },
  format(s, table, cell) {
    const $cell = $(cell.innerHTML)
    return $cell.data("sort-order")
  },
  type: "numeric",
})

$.tablesorter.addParser({
  id: "priority",
  is(s, table, cell) {
    if (!cell) {
      return false
    }

    let $cell
    if (cell.children.length > 0) {
      $cell = $(cell.innerHTML)
    } else {
      $cell = $(cell)
    }

    if ($cell.data("sort-parser") === "priority") {
      return true
    }
    return false
  },
  format(s, table, cell) {
    const $cell = $(cell.innerHTML)
    return $cell.data("sort-order")
  },
  type: "numeric",
})

$.onmount("table[data-sort]", ({ selector }) => {
  $(selector).each((_, el) => {
    const $this = $(el)

    const options = {
      cssAsc: "sort-down",
      cssDesc: "sort-up",
      cssHeader: "sort",
      cssIconNone: "fas fa-sort",
      cssIconAsc: "fas fa-sort-down",
      cssIconDesc: "fas fa-sort-up",
      headerTemplate: "{content} {icon}",
      emptyTo: "emptyMin",
    }

    const exceptions = $this.find("[data-no-sort]")
    if (exceptions) {
      exceptions.each((index, element) => {
        const column = $("th").index(element)
        options.headers = options.headers || {}
        options.headers[column] = { sorter: false }
      })
    }

    const $sortCol = $this.find(".sort-up, .sort-down")
    if ($sortCol.length > 0) {
      const col = $sortCol.index()
      const dir = $sortCol.hasClass("sort-down") ? 1 : 0
      options.sortList = [[col, dir]]
    }

    if ($this.parent().data("sort-list")) {
      options.sortList = $this.parent().data("sort-list")
    }

    if (!el.hasAttribute("data-text-extractor")) {
      options.textExtraction = (node) => $(node).text().replace("N/A", "-1")
    }

    $this.tablesorter(options).bind("sortEnd", () => {
      $this.parent().data("sort-list", el.config.sortList)
    })
  })
})
