/* eslint-disable no-use-before-define */
import { scaleLinear, max } from "d3"

const roundNum = (num) => Math.round((num + Number.EPSILON) * 100) / 100

/**
 * Populates the tooltip with content
 * @param {*} selection d3 selection of the tooltip div
 * @param {*} data data used for populating the tooltip
 * @param {*} color color scheme from bar chart
 */
function pipelineTooltipContent(selection, data, color) {
  const { datum, labels } = data
  const pipelineData = datum.pipeline_utilization
  const totalFills = pipelineData.candidates + pipelineData.non_candidates

  // Process data
  const tooltipData = [
    {
      label: labels.pipeline_utilization,
      data: pipelineData.candidates,
      colorKey: "pipeline_utilization",
    },
    {
      label: labels.non_candidates,
      data: pipelineData.non_candidates,
      colorKey: "non_candidates",
    },
  ]

  // Classes of each table row element, from left to right
  const mapToClass = ["row-label f-12", "bar-container", "row-data f-12"]

  const formatMetric = (d) =>
    `${roundNum(d)} <span>(${((d / totalFills) * 100.0).toFixed(0)}%)</span>`

  // Set scale
  let maxNum = max(tooltipData, (d) => d.data)
  maxNum = maxNum > 0 ? maxNum : 1
  const barScale = scaleLinear().domain([0, maxNum]).range([0, 100])

  // Add header date
  selection.select("#header-date").text(datum.full_date)

  // Handle total row
  const totalRow = selection.select("#total-row")
  const translationKey =
    totalFills > 1 || totalFills === 0 ? "positions_with_plans_filled" : "position_with_plan_filled"
  totalRow.text(translationKey.t("dashboard", null, null, null, [totalFills]))

  // Handle Table
  const table = selection.select(".widget-table")

  // Render table invisible if no data exists
  table.style("display", () => (totalFills === 0 ? "none" : "block"))

  const rows = table
    .select("tbody")
    .selectAll("tr")
    .data(tooltipData, (d) => d.label)
    .join("tr")
    .attr("id", (_, i) => `group_${i}`)

  rows
    .selectAll("td")
    .data((d) => [d.label, d, d])
    .join("td")
    .attr("class", (_, i) => mapToClass[i])

  // Label
  table
    .selectAll(".row-label")
    .style("font-weight", (d) => (d === labels.pipeline_utilization ? "bold" : "normal"))
    .text((d) => d || "None")

  const barContainers = table.selectAll(".bar-container")

  barContainers
    .selectAll("div")
    .data((d) => [d])
    .join("div")
    .attr("class", "bar")
    .style("background-color", (d) => color(d.colorKey))
    .style("width", barScale(0))
    .transition()
    .duration(500)
    .style("width", (d) => `${barScale(d.data)}%`)

  table
    .selectAll(".row-data")
    .selectAll("span")
    .data((d) => [d])
    .join("span")
    .attr("class", "f-12")
    .style("border-bottom", "none")
    .style("font-weight", (d) => (d.label === labels.pipeline_utilization ? "bold" : "normal"))
    .html((d) => formatMetric(d.data))
}

export default pipelineTooltipContent
