import { GridStack } from "gridstack"
import "gridstack/dist/h5/gridstack-dd-native"
import { REACT_WIDGETS } from "../dashboards/react-widget-mounter"

const { $, Pjax } = window

$.onmount(".grid-stack", () => {
  const grid = GridStack.init({
    column: 6,
    cellHeight: "167px",
    cellHeightThrottle: 5,
    disableResize: true,
    disableDrag: true,
    handle: ".widget-title",
    margin: 6,
  })

  window.grid = grid

  function updateGrid() {
    $.ajax({
      type: "PUT",
      url: "save_dashboard",
      data: { widgets: grid.save(false) },
    }).done(() => {
      const selector = Pjax.targetContainer("page-actions")
      $.pjax.reload({ container: selector, fragment: selector })
    })
  }

  if ($(".grid-stack").children().length > 0) {
    return
  }

  grid.setAnimation(false)

  grid.on("added", (_, widgetsToAdd) => {
    // Filter out react-based widgets b/c they handle the spinner within the component
    const pjaxWidgets = widgetsToAdd.filter((w) => !REACT_WIDGETS.includes(w.module_name))

    pjaxWidgets.forEach((w) => {
      const gridItemContent = $(w.el).children(".grid-stack-item-content")
      gridItemContent.append("<div class=spinner></div>")

      gridItemContent.on("pjax:success", (e) => {
        $(e.currentTarget).children(".spinner").remove()
        // Re-enables dragging after the pjax request completes
        // because the the drag handle isn't present before then.
        grid.enableMove(false)
        grid.enableMove(true)
      })
    })
    $.onmount()
  })

  grid.on("removed", updateGrid)

  grid.on("dragstop", updateGrid)

  grid.load(window.serializedData, true)
})

$.onmount("[data-action=delete-widget]", () => {
  const { grid } = window
  $("[data-action=delete-widget]").off("click")
  $("[data-action=delete-widget]").click((e) => {
    grid.removeWidget($(e.target).parents(".grid-stack-item")[0])
  })
})
