$.onmount '#pto_types .is-sortable', ->
  $sortable = $(@)
  $sortable.sortable
    update: ->
      order = $sortable.sortable('toArray', attribute: 'data-id')

      options = {
        url: $sortable.parent().data('pjax-url'),
        container: '#pto_types',
        fragment: '#pto_types',
        push: false,
        scrollTo: false,
        data: {
          modal: 'order',
          order: order
        },
        type: 'PUT'
      }

      $.pjax(options)
      $.pjax.xhr = null
