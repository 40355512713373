class ButtonHelpers
  @getElementType: (element) ->
    buttonObject = {}
    element = switch true
      when @lastTargetElement.element instanceof jQuery
        @lastTargetElement.element
      when element.tagName?
        $(element)
      when element instanceof jQuery
        element
      when (typeof element.target == 'object' && element.target?)
        $(element.target)

    buttonObject[element.prop('tagName')] = element

    buttonObject

  @getObjectAndAccessor: (element) ->
    element = @getElementType(element)
    switch (true)
      when typeof element.INPUT == 'object'
        element: element.INPUT,
        accessor: 'val'
      when typeof element.BUTTON == 'object'
        element: element.BUTTON,
        accessor: 'text'

  @setAllButtonsState: (target, state) ->
    @allButtons = target.element.closest('form').find('.btn--large')
    if(state == 'disabled')
      @allButtons.prop('disabled', true)
      @allButtons.addClass('btn-disabled')
    else if(state == 'enabled')
      @allButtons.prop('disabled', false)
      @allButtons.removeClass('btn-disabled')

  @memoizeLastTargetElement: (target) ->
    @lastTargetElement = {}
    @lastTargetElement.element = target.element
    @lastTargetElement.enabledLabel = target.element[target.accessor]()

  @getTargetElementText: (state) ->
    if (state == 'enabled' && @lastTargetElement.enabledLabel)
      @lastTargetElement.enabledLabel
    else
      action = @lastTargetElement.element.attr('data-action')
      action = @lastTargetElement.element.attr('value').toLowerCase() unless action
      return '' unless action
      key = action + '_' + state
      key.t('button_defaults')

  @setTargetElementText: (target, state) ->
    if state == 'disabled'
      @memoizeLastTargetElement(target)

    target.element[target.accessor](@getTargetElementText(state))

  # V2 PJAX modals track last clicked submit tag
  @findClickedButton: ($form) ->
    name = $form.find('input[name=submit_button]').val()
    return '' unless name && name.length > 0
    $form.find("button[type=submit][name=#{name}]")

  # If the target element is not a button or a submit element,
  # then find the button or submit element on the form and return it.
  @makeButtonOrSubmit: (element) ->
    $element = element

    unless element instanceof jQuery
      $element = $(element)

    type = $element.prop('type')

    if type? and (type is 'submit' or type is 'button')
      return element

    $form = $element.closest('form')
    if $form?
      button = @findClickedButton($form)
      button = $form.find(':submit') unless button.length
      button = $form.find(':button:not(.tox-tbtn)') unless button.length
      if button?
        return button[0]

    Sentry.captureException new Error(
      'Unable to find a button or a submit element on the form.'
    )

    element

  # Sets state for given target. A target can be HTML tag type of input or
  # button given as a jQuery object, Javascript Event object or a Javascript
  # EventTarget.
  # Two valid states for target are 'disabled' and 'enabled'.
  # On 'disable' the target is memorized so the corresponding 'enable' call
  # does not require a target. The text for target element is determined with
  # the key combination of attribute 'data-action' + '_disabled'.
  # See 'button_defaults' scope in en.coffee
  #
  # Workflow:
  # 1.) this.setButtonState(myTargetElement, 'disable')
  #     -> All elements with 'button' class are disabled, text of target
  #        element is updated
  # 2.) this.setButtonState({}, 'enabled')
  #     -> All elements with 'button' class are enabled, text of target
  #       element is restored to initial value
  @setButtonState: (target, state) ->
    # Check if we already re-enabled all buttons after memoizing a disable
    if (!$.isEmptyObject(target)) || (!$.isEmptyObject(@lastTargetElement))
      if state == 'disabled'
        @lastTargetElement = {}
      target = @makeButtonOrSubmit(target)
      return unless target
      target = @getObjectAndAccessor(target)
      @setTargetElementText(target, state)
      @setAllButtonsState(target, state)

window.ButtonHelpers = ButtonHelpers
