$.onmount '#edit-people', ->
  constants = {
    $modal: $(@)
    $addColumn: $(@).find('[data-action=add-column]')
    $addRow: $(@).find('[data-action=add-row]')
    $tableBody: $(@).find('tbody')
    $columnSelect: -> constants.$modal.find('.column-select')
  }

  functions = {
    onSelectChange: (e) ->
      $select = $(@)
      $selectedOption = $(@).find('option:selected')
      attribute = $selectedOption.val()
      if $selectedOption.data('unique-key')
        inputGroupSelector = ".unique-key-#{$selectedOption.data('unique-key')}"
      else
        inputGroupSelector = "[name$='[#{attribute}]']"
      $order = $select.closest('th').find('[name*=additional_fields_order]')
      $order.val(attribute)

      constants.$modal.find('tbody tr').each ->
        $inputGroup = $(@).find("#additional-columns #{inputGroupSelector}")
          .parent().clone()
        $inputGroup.find(inputGroupSelector).attr('disabled', false)
        $field = $(@).find(".#{$select.attr('id')}")

        $field.empty()
        $field.append($inputGroup)

      functions.changeOptions()
      functions.resizeSelect(@)

    onAddColumnClick: ->
      $select = constants.$modal.find('#column-1').closest('th').clone()
      columnNumber = constants.$columnSelect().length + 1

      # header
      $select.find('.column-select').attr('id', "column-#{columnNumber}")
      constants.$columnSelect().each ->
        $select.find("option[value=#{$(@).find('option:selected').val()}]")
          .attr('disabled', true)
          .attr('selected', false)
          .addClass('hidden')
      $options = $select.find('option').not('.hidden')
      if $options.length == 1
        constants.$addColumn.addClass('hidden')
      $selectedOption = $($options[0])
      $selectedOption.attr('selected', 'selected')
      attribute = $selectedOption.val()
      $order = $select.find('[name="people[additional_fields_order[1]]"]')
      $order.attr('id', "people_additional_fields_order[#{columnNumber}]")
      $order.attr('name', "people[additional_fields_order[#{columnNumber}]]")
      $order.val(attribute)
      constants.$modal.find('thead tr').append($select)

      # body
      constants.$modal.find('tbody tr').each ->
        $inputGroup = $(@).find("#additional-columns [name$='[#{attribute}]']")
          .parent().clone()
        $inputGroup.find("[name$='[#{attribute}]']").attr('disabled', false)

        $(@).append("<td class=column-#{columnNumber}></td>")
        $(@).find(".column-#{columnNumber}").append($inputGroup)

      functions.changeOptions()

      column = constants.$modal.find("#column-#{columnNumber}")[0]
      functions.resizeSelect(column)

    onAddRowClick: ->
      index = constants.$tableBody.find('tr').length
      $personRow =  _.template($('#person-row-template').html())
      constants.$tableBody.append($personRow(index: index))

      constants.$columnSelect().each ->
        $select = $(@)
        $selectedOption = $select.find('option:selected')
        attribute = $selectedOption.val()
        if $selectedOption.data('unique-key')
          inputGroupSelector = ".unique-key-#{$selectedOption.data('unique-key')}"
        else
          inputGroupSelector = "[name$='[#{attribute}]']"

        columnNumber = $select.attr('id').split('-')[1]
        $tr = constants.$tableBody.find('tr:last-child')
        $inputGroup = $tr.find("#additional-columns #{inputGroupSelector}")
          .parent().clone()
        $inputGroup.find(inputGroupSelector).attr('disabled', false)

        $tr.append("<td class=column-#{columnNumber}></td>")
        $tr.find(".column-#{columnNumber}").append($inputGroup)

    changeOptions: ->
      constants.$columnSelect().find('option.hidden')
        .removeClass('hidden')
        .attr('disabled', false)
      $selectedOptions = constants.$columnSelect().find('option:selected')
      constants.$columnSelect().each ->
        $select = $(@)
        $selectedOptions.each ->
          return if $(@).val() == $select.find('option:selected').val()

          $select.find("[value=#{$(@).val()}]")
            .attr('disabled', true)
            .addClass('hidden')

    resizeSelect: (el) ->
      $selectedOption = $(el).children('[value="' + el.value + '"]')
        .attr('selected', true)
      selectedIndex = $selectedOption.index()

      $nonSelectedOptions = $(el).children().not($selectedOption).remove()
        .attr('selected', false)

      $selectedOption.remove()
      $(el).append($nonSelectedOptions)
      if (selectedIndex >= $nonSelectedOptions.length)
        $(el).append($selectedOption)
      else
        $(el).children().eq(selectedIndex).before($selectedOption)
  }

  constants.$addColumn.on 'click', functions.onAddColumnClick
  constants.$addRow.on 'click', functions.onAddRowClick
  $(document).on 'change', '.column-select', functions.onSelectChange

  constants.$columnSelect().each ->
    functions.resizeSelect(@)
