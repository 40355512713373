$.onmount '#ical-feeds-table', ->
  functions = {
    copyToClipboard: (e) ->
      e.preventDefault()
      $copyUrl = $(@).find('.copy-url')
      $copied = $(@).find('.copied')

      $linkText = $(@).closest('tr').find('.link-text')

      range = document.createRange()
      range.selectNodeContents($linkText[0])

      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      document.execCommand('copy')

      $copyUrl.hide()
      $copied.show()

      setTimeout ->
        $copyUrl.show()
        $copied.hide()
      , 3000
  }

  $(@).find('[data-action=copy-url]').on 'click', functions.copyToClipboard
