$.onmount '.year .months td.day', ->
  $(@).on 'click', (e) ->
    $dispatchEl = $(@).closest('[data-pjax-url]')
    url = $dispatchEl.data().pjaxUrl
    selector = Pjax.targetContainer('modal')

    $.pjax({
      url: url,
      container: selector,
      push: false,
      scrollTo: false,
    })

$.onmount '.year', ->
  $year = $(@)

  functions = {
    updateYearLinks: ($prev, $next, node) ->
      data = $(node).data()
      year = data.year
      url = node.href
      newPrevUrl = url.replace(year, year - 1)
      newNextUrl = url.replace(year, year + 1)

      $prev.prop('href', newPrevUrl)
      $prev.data('year', year - 1)
      $next.prop('href', newNextUrl)
      $next.data('year', year + 1)
  }

  $.onmount '.previous', ->
    $(@).on 'pjax:started', ->
      $next = $year.find('.next')

      functions.updateYearLinks($(@), $next, @)

  $.onmount '.next', ->
    $(@).on 'pjax:started', ->
      $prev = $year.find('.previous')

      functions.updateYearLinks($prev, $(@), @)
