String.prototype.extension ||= ->
  FileHelper.extension @.toString()

class FileHelper
  @extension: (uri) ->
    everythingUntilLastPeriod = /^.*\./
    @basename(uri).replace(everythingUntilLastPeriod, '')

  @basename: (path) ->
    backslashes = /\\\\/g
    query_string = /\?.*$/
    everythingUntilLastSlash = /.*[\/\\]/
    path
      .replace(query_string, '')
      .replace(backslashes, '/')
      .replace(everythingUntilLastSlash, '')
