(($) ->
  $.fn.maskAllInputs = ->
    @find('input[data-maskedinput]').each ->
      $(@).maskInputs()

  $.fn.maskInputs = (options) ->
    helper = new MaskedInputsHelper(options)

    $el = $(@)
    maskingType = $el.data('maskedinput')

    if typeof helper[maskingType] == 'function'
      helper[maskingType]($el)
    else
      $.error "unknown masking type: #{maskingType}"

    $el.on 'keypress', (e) ->
      if Keyboard.keyMatchesName e.which, 'enter' &&
         $el.val().indexOf('_') != -1
        e.preventDefault()

    $(@)

  class MaskedInputsHelper
    constructor: (@options) ->
      @options ||= {}

    closeDatePicker: (el) ->
      el?[0]?.picker?.hide()

    phone: ($el) ->
      $el.mask '(999) 999-9999', @options

    phoneext: ($el) ->
      $el.mask '(999) 999-9999? ext. 999999', @options

    ssn: ($el) ->
      $el.mask '999-99-9999', @options

    date: ($el) ->
      mask = 'YYYY-MM-DD'
      self = @
      $.extend(
        @options,
        placeholder: mask
        completed: -> self.closeDatePicker @
      )
      $el.mask '9999-99-99', @options

    monthday: ($el) ->
      self = @
      $.extend(
        @options,
        placeholder: 'Jan 01'
        completed: -> self.closeDatePicker @
      )
      $el.mask 'aaa 99', @options

    time: ($el) ->
      $.mask.definitions['H'] = '[0-1]'
      $.mask.definitions['h'] = '[0-9]'
      $.mask.definitions['M'] = '[0-5]'
      $.mask.definitions['m'] = '[0-9]'
      $.mask.definitions['P'] = '[AaPp]'
      $.mask.definitions['p'] = '[Mm]'

      $el.mask 'Hh:Mm Pp', @options

    zip: ($el) ->
      $el.mask '99999', @options

    fullzip: ($el) ->
      $el.mask '99999?-9999', @options

    state: ($el) ->
      $el.mask 'aa', @options

    cc_exp: ($el) ->
      $el.mask '99/99', @options

)(jQuery)
