const $ = window.jQuery

$(() => {
  $.onmount("[data-action=select-all]", (behavior) => {
    $(behavior.selector).on("change", (e) => {
      const name = $(e.currentTarget).prop("name") || $(e.currentTarget).data().name
      $(`[name='${name}']`).prop("checked", $(e.currentTarget).prop("checked"))
      const $form = $(e.currentTarget).parents("form")
      const count = $form.find("input[type=checkbox]:checked:not([data-action=select-all])").length
      $("[data-marker=count]").text(count)
      $form.trigger("checkbox:select-all:click")
    })
  })
})
