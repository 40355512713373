class Modal
  constructor: (el) ->
    window.currentModal = @
    @$el = $(el)

    @data = @$el.data()
    @modalType = @data.modal

    @$overlay = @$el.closest('.modal-overlay')
    @$modalContent = @$el.find([
      Pjax.targetContainer('modal-content'),
      Pjax.targetContainer('upmodal-content'),
      Pjax.targetContainer('modal-confirm-content')
    ].join(', '))

    @open()

  @selector: (container) ->
    "[data-modal='#{container}']"

  open: =>
    $('.main-content').addClass('no-print')
    $('body').addClass('modal-active')
    @addEvents()
    @$overlay.fadeIn =>
      @$el.trigger('modal:open', @)

    # If one wants to autofocus a specific element, this is done by adding the
    # data attribute `data-input-focus` to the it. TinyMce autofocusing is
    # handled separately using the tinyMce data field `data-startup-focus`.
    autofocusTinyMce = $('[data-startup-focus]')
    autofocusInput = $('[data-input-focus]')
    if autofocusTinyMce.length > 0 && autofocusTinyMce.first().attr('data-startup-focus') == "true"
      return
    else if autofocusInput.length > 0 && autofocusInput.first().attr('data-input-focus') == "true"
      autofocusInput.trigger('focus')
    else
      @$el.find('input[type=text]:not(:disabled, [readonly],[data-widget=datepicker]), select')
        .first()
        .trigger('focus')

  # Add a hidden input element to the the form of which the submit button was
  # clicked. This helps in the 'startSave' callback to identify which submit
  # button was clicked and update its state.
  addSubmitButtonName: (button) ->
    $('<input />').attr('type', 'hidden')
      .attr('name', 'submit_button')
      .attr('value', button.name)
      .appendTo($(button.form))

  addEvents: =>
    $('body').on('keyup', @closeForEscape)
    @$overlay.click(@clickEvent)
    @$modalContent.on('pjax:start', @startSave)
    $('[data-action=save-and-add]').click(@saveAndAdd)
    self = @
    @$modalContent.on(
      'click', 'button[type=submit], input[type=submit]', ->
        self.addSubmitButtonName(this)
    )

    $('.radio input, .radio select').on 'click', @checkRadio
    $('.radio select').on 'focus', @checkRadio

    @$el.find('input[type=number]').on 'mousewheel', (e) -> e.preventDefault()

  checkRadio: ->
    if ($(@).closest('.radio').find('input[type=radio]:checked').length == 0)
      if ($(@).is('[data-maskedinput]'))
        $(@).closest('.radio').find('input[type=radio]').attr('checked', true)
      else
        $(@).closest('.radio').trigger 'click'
        $(@).trigger 'click'
        $(@).trigger 'focus'

  clickEvent: (e) =>
    $target = $(e.target)

    return if $target.data('pjax_click')

    e.stopPropagation()
    @close(e) if $target.parents('a').data('action') == 'close' ||
                 $target.data('action') == 'close'

  errorMessages: ->
    error_classes = [
      '.form-error'
      '.form-error-message'
      '.form-error-messages.active'
      '.alert-critical'
    ]
    @$el.find(error_classes.join(', '))

  showError: (text) ->
    @errorMessages().first().find('> div').text(text).addClass('active')

  clearError: ->
    @errorMessages().first().find('> div').text('').removeClass('active')

  saveAndAdd: (e) =>
    @addUrl = $(e.target).data('add-url')

  modalSubmitForm: ->
    @$modalContent
      .find('form[data-pjax-submit=modal-content],
        form[data-pjax-submit=upmodal-content],
        form[data-pjax-submit=modal-confirm-content]
      ')
      .not('[data-secondary-form=true]')
      .not('[data-follow-up-form=true]')

  startSave: (e, _xhr, _options) =>
    $targetForm = $(e.relatedTarget)
    return unless $targetForm.length
    $modalSubmitForm = @modalSubmitForm()

    if $targetForm.data('secondary-form')
      ButtonHelpers.setButtonState($targetForm, 'disabled')
    else if $targetForm.data('follow-up-form')
      @$form = $targetForm
      ButtonHelpers.setButtonState($targetForm, 'disabled')
      @$modalContent.on('pjax:end', @afterSave)
    else if $targetForm.is($modalSubmitForm)
      @$form = $modalSubmitForm
      ButtonHelpers.setButtonState($modalSubmitForm, 'disabled')
      @$modalContent.on('pjax:end', @afterSave)
    else if $targetForm.data('emulate-submit')
      @$form = $targetForm
      ButtonHelpers.setButtonState($targetForm, 'disabled')
      @$modalContent.on('pjax:end', @afterSave)

  # This function differs from the same function in v2-stubs/modal.coffee. The
  # v1 modal.coffee seeems to automatically reload the parent container, but
  # here wo do not, requiring one to do a manual call to reloadTargetContainer
  # in each respective piece of UI that uses this function.
  afterSave: (e, xhr, options) =>
    @$modalContent.off('pjax:end', @afterSave)

    formData = e.relatedTarget.dataset

    reloadedModalDataset = $(xhr.responseText).find('.modal').data()
    @data.modalReloadUrl = reloadedModalDataset.modalReloadUrl || @data.modalReloadUrl

    errors = @errorMessages()
    if errors.length == 0 or (errors.get(0) and errors[0].innerText.length == 0)
      if options.data == 'refresh-data' || formData.action == 'refresh-data'
        return
      else if formData.followUpForm
        @$el.attr('class', $(xhr.responseText).find('.modal').attr('class'))
        @$form = null
        @$el.trigger('modal:followUpForm')
      else if @data.modalCustomClose
        @$el.trigger('modal:save', @)
      else
        $.when(@$el.trigger('modal:save', @)).done ( => @close())
    else
      @$el.trigger('modal:error', @)
      @reloadTargetContainer() if @data.reloadOnError
      @$form = null
      @$el.find('[data-action=close]').click(@clickEvent)

  addAnother: =>
    return unless @addUrl

    $.pjax
      url: @addUrl,
      container: "[data-pjax-container='modal']"
      push: false
      scrollTo: false

  reloadTargetContainer: =>
    return unless @data.modalReload

    targetContainer = Pjax.targetContainer(@data.modalReload)
    containerUrl = @data.modalReloadUrl
    modalReloadPush = @data.modalReloadPush || false
    modalReloadQuery = @data.modalReloadQuery || false
    containerUrl += window.location.search if modalReloadQuery

    unless $(targetContainer).length > 0
      window.location.reload()
      return

    if (containerUrl)
      return $.pjax(
        {
          url: containerUrl,
          container: targetContainer,
          fragment: targetContainer,
          push: modalReloadPush,
          scrollTo: false
        }
      )
    else if @data.reloadURL
      $.pjax.reload
        url: @data.reloadURL,
        container: targetContainer,
        push: false
    else
      $.pjax.reload(targetContainer, { fragment: targetContainer })

  closeForEscape: (e) =>
    @close() if Keyboard.keyMatchesName e.which, 'esc'

  close: (e) =>
    e?.preventDefault()
    @$el.trigger('modal:close', @)
    delete window.currentModal
    @$modalContent.off('pjax:start', @startSave)
    @$overlay.fadeOut(@cleanup.bind(@))

  cleanup: ->
    $('.main-content').removeClass('no-print')
    $('body').removeClass('modal-active').off('keyup').css('height', '')
    $('body').find('.pika-single').remove()
    @removeEditors()
    $(window).off 'resize'
    @$overlay.remove()

  removeEditors: ->
    $('.modal .tinymce').each -> tinymce.remove("##{@id}")

window.Modal = Modal

$.onmount '[data-modal]', -> new Modal(@)
