menuItemHeight = 36

# Drop down menus
class DropdownMenu
  @handleClick: (event) ->
    event.stopPropagation()
    event.preventDefault()
    menu = $(event.currentTarget).siblings('.dropdown-menu')
    # Account for dropdowns from a button nested in a form/ a.k.a rails button_to
    if (!menu || !menu.length)
      menu = $(event.currentTarget).parent().siblings('.dropdown-menu')
    hide = menu.is(':visible')

    if ($('.table').find(menu).length || $('.table-with-menu').find(menu).length)
      table = $('.table-container')[0] || $('.table')[0] || $('.table-with-menu')[0]
      tableHeight = table?.getBoundingClientRect().bottom
      tableTop = table?.getBoundingClientRect().top
      buttonBoundingClientRect = event.currentTarget.getBoundingClientRect()
      menuHeight = menu[0].children?.length * menuItemHeight
      menuBottom = buttonBoundingClientRect.y + buttonBoundingClientRect.height + menuHeight
      menuTop = buttonBoundingClientRect.y - menuHeight

      # base positioning adjustment, if needed
      unless menu.hasClass('skip-base-positioning-adjustment')
        menu.css({top: buttonBoundingClientRect.y + buttonBoundingClientRect.height + "px"})

      # transforms the menu if it needs to be right-aligned
      if (menu.hasClass('dropdown-menu-right'))
        menu.css({right: "calc(#{parseInt(menu.css('width'))}px / 2 - #{buttonBoundingClientRect.width}px)"})

      # If the menu is going to overflow the table then flip the menu above, unless it will overflow there as well.
      if (menu.length && tableHeight && (tableHeight - menuBottom < 0) && (menuTop > tableTop))
        menu.css({transform: "translateY(calc(-100% - 2rem))"})

    $('.dropdown-menu').not($(event.currentTarget).parents('.dropdown-menu')).hide()
    menu.show() unless hide

  @handleVisibility: (el) ->
    $(el).on 'click', -> $('.dropdown-menu').hide()

    $(el).on 'click', '.dropdown-menu', (e) ->
      e.stopPropagation()

    $(el).on 'click', '.dropdown-menu-link:not(.keep-open)', ->
      $(el).find('.dropdown-menu').hide()

  @highlightNext: (event) ->
    el = event.currentTarget
    $menu = $(el).siblings('.dropdown-menu')
    if $menu.is(':hidden')
      $menu.show()
    else
      $active = $menu.find('.dropdown-menu-link.active')
      $next = $active.next()
      if $next.length == 0
        $next = $active.siblings().first()

      $active.removeClass('active')
      $next.addClass('active')

  @highlightPrev: (event) ->
    el = event.currentTarget
    $active = $(el).siblings('.dropdown-menu').find('.dropdown-menu-link.active')
    $prev = $active.prev()
    if $prev.length == 0
      $prev = $active.siblings().last()

    $active.removeClass('active')
    $prev.addClass('active')

  @handleEnter: (event) ->
    el = event.currentTarget
    $active = $(el).siblings('.dropdown-menu').find('.dropdown-menu-link.active')
    $active.click()

jQuery ($) ->
  $.onmount '.dropdown .dropdown-link', ->
    $component = $(@)
    $component.click (event) ->
      DropdownMenu.handleClick(event) unless $component.hasClass('disabled') || $component.hasClass('readonly')
    $component.on 'keyup', (event) ->
      switch event.keyCode
        when 40 then DropdownMenu.highlightNext(event)
        when 38 then DropdownMenu.highlightPrev(event)
        when 13 then DropdownMenu.handleEnter(event)

  DropdownMenu.handleVisibility(document)

  $.onmount '.tabs-container, .stages-container', ->
    $(@).tabDropDownMenu()

window.DropdownMenu = DropdownMenu
