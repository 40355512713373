import "pjax"
import "helpers/symbols"
import "helpers/"
import "company"
import "components/"
import "dashboards/"
import "headcount_plans"
import "importers/"
import "job_application"
import "job_application_votes"
import "job_application_files"
import "job_sources"
import "login"
import "pjax_paginated_table"
import "people_edit"
import "previews/"
import "profile"
import "modals/"
import "uploaders/"
import "ui"
import "settings/"
import "succession_strategies"
import "succession_plan_panel"
import "subforms/"
import "positions"
import "overrides/"
import "wysiwyg"
import "allocations"
import "assets"
import * as BudgetTable from "v2/modals/position_modal/budget_table"
import * as FundingSources from "v2/modals/position_modal/position_funding_sources"
import * as FilledByInput from "v2/modals/position_modal/filled_by_input"
import * as TitleInput from "v2/modals/position_modal/title_input"
import ReactRailsUJS from "react_ujs"
import { InlineOrgUnitForm } from "v2/subforms/inlineOrgUnitForm"

window.InlineOrgUnitForm = InlineOrgUnitForm
window.BudgetTable = BudgetTable
window.FundingSources = FundingSources
window.FilledByInput = FilledByInput
window.TitleInput = TitleInput

// Support component names relative to this directory:
const componentRequireContexts = [
  require.context("v2/react/components", true),
  require.context("v2/react", true),
]
ReactRailsUJS.useContexts(componentRequireContexts)
