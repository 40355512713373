import { each } from "lodash"
import { select } from "d3"

import "./react-widget-mounter"
import "./widgets"
import DonutChart from "./charts/donut_chart"
import MinMaxAverageChart from "./charts/min_max_average_chart"
import StackedHorizontalBarChart from "./charts/stacked_horizontal_bar_chart"
import StackedBarMounter from "./widgets/stacked_bar_mounter"
import GaugeChartMounter from "./widgets/succession/gauge_chart_mounter"

// Capture jQuery instance from the window global.
const { $ } = window

/**
 * Reloads the widget using the change event of a select or text input.
 *
 * @param {JQuery.Event} changeEvent Delegated event; current target holds new
 *   group clause key.
 * @returns {void}
 * @private
 */
function useEventToReloadWithGroupBy(changeEvent) {
  const $curr = $(changeEvent.currentTarget)
  const $pjaxContainer = $curr.closest("[data-pjax-render]")
  const baseURL = $pjaxContainer.data("pjaxRender")

  if (!baseURL) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to reload widget using '${baseURL}'`)
    return
  }

  select(`[data-pjax-render='${baseURL}']`).property("groupByTriggered", true)

  $.pjax({
    url: baseURL,
    data: { group_by: $curr.val() },
    // I'm not sure this selector is unique enough, but it should work for now.
    container: `[data-pjax-render='${baseURL}']`,
    replace: false,
    push: false,
    scrollTo: false,
  })
}

/**
 * Animates HTML progress bars, which are nested within `nodeOrSelector`,
 * when they first enter the DOM.
 *
 * @param {string | Node | HTMLElement} nodeOrSelector
 * @returns {void}
 */
function ProgressBarAnimator(nodeOrSelector) {
  select(nodeOrSelector)
    .selectAll(".progress-bar-wrapper")
    .style("opacity", 0)
    .transition()
    .duration(250)
    .style("opacity", 1)

  select(nodeOrSelector)
    .selectAll(".progress-bar")
    .each((_datum, index, nodes) => {
      const selectionOfProgressBar = select(nodes[index])
      const endWidth = selectionOfProgressBar.style("width")

      selectionOfProgressBar
        .style("width", 0)
        .transition()
        .duration(350)
        .delay(300)
        .style("width", endWidth)
    })
}

/**
 * Mounts configured charts to the DOM.
 *
 * @param {object} mountChartsOrAnimators
 * @returns {void}
 */
function bootstrap(mountChartsOrAnimators) {
  each(mountChartsOrAnimators, (ChartOrAnimator, selector) => {
    $.onmount(selector, function mountChartOrAnimator() {
      return ChartOrAnimator(this)
    })

    if (selector.includes("data-use-animator")) {
      return
    }

    window.addEventListener("resize", () => {
      $(selector).empty()
      $(selector).each((_index, el) => {
        ChartOrAnimator(el, { disableAnimation: true })
      })
    })
  })

  $("body").on("change", "[data-pjax-reload-group-by]", useEventToReloadWithGroupBy)
}

bootstrap({
  "[data-use-animator=ProgressBarAnimator]": ProgressBarAnimator,
  "[data-use-chart=DonutChart]": DonutChart,
  "[data-use-chart=MinMaxAverageChart]": MinMaxAverageChart,
  "[data-use-chart=StackedHorizontalBarChart]": StackedHorizontalBarChart,
  "[data-use-chart=StackedBar]": StackedBarMounter,
  "[data-use-chart=GaugeChart]": GaugeChartMounter,
})
