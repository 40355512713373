functions = {
  dailySchedulesOfficeDayChange: ->
    $schedule = $(@).closest('.input-group-row').find('.schedule')

    if (@.checked)
      $schedule.removeClass('hidden')
    else
      $schedule.addClass('hidden')

  resetTotalHours: (e) ->
    $schedule = $(@).closest('.input-group-row').find('.schedule')
    $from = $schedule.find('#schedule_daily_schedules_start_time')
    $to = $schedule.find('#schedule_daily_schedules_end_time')
    $totalHours = $schedule.find('#schedule_daily_schedules_total_hours')
    startTime = $from.find(':selected').val()
    endTime = $to.find(':selected').val()

    totalHours = endTime - startTime
    totalHours = totalHours + 24 if totalHours < 0

    $totalHours.val(totalHours)

  syncDays: ($officeDays) -> (e) ->
    e.preventDefault()

    $currentSchedule = $(@).closest('.input-group-row').find('.schedule')
    $currentFrom = $currentSchedule.find('#schedule_daily_schedules_start_time')
    $currentTo = $currentSchedule.find('#schedule_daily_schedules_end_time')
    $currentTotalHours = $currentSchedule.find('#schedule_daily_schedules_total_hours')
    $schedules = $officeDays.find('.schedule')
    $from = $schedules.find('[name$=\\[start_time\\]]')
    $to = $schedules.find('[name$=\\[end_time\\]]')
    $totalHours = $schedules.find('[name$=\\[total_hours\\]]')
    startTime = $currentFrom.find(':selected').val()
    endTime = $currentTo.find(':selected').val()
    totalHours = $currentTotalHours.val()

    $from.val(startTime)
    $to.val(endTime)
    $totalHours.val(totalHours)
}

$.onmount '#office-days', ->
  $officeDays = $(@)
  $officeDays.find('[name$=\\[office_day\\]]').on 'change', functions.dailySchedulesOfficeDayChange

  $officeDays.find('[name$=\\[start_time\\]]').on 'change', functions.resetTotalHours
  $officeDays.find('[name$=\\[end_time\\]]').on 'change', functions.resetTotalHours

  $officeDays.find('[data-action=sync-days]').on 'click', functions.syncDays($officeDays)
