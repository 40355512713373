$.onmount "form.new_company[id*='new_company']", ->
  $datepicker = $(@).find('[data-widget=monthdaypicker]')

  $datepicker.each ->
    @picker = new Pikaday
      field: this
      format: 'MMM DD'
      defaultDate: 'Jan 01'
      setDefaultDate: true
      theme: 'hide-year'
    @picker.setDate($(this).val())

    $(this).prop('picker', @picker)
