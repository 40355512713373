const { tableToCSV, $ } = window

$.onmount("[data-action=export]", function () {
  $(this).on("click", (e) => {
    const selector = $(this).data("table")
    const filename = $("h1").first().text().toLowerCase().replace(/\s/gi, "_") + ".csv"
    tableToCSV($(selector), filename, e.currentTarget)
  })
})

$.onmount("[data-action=export-modal]", function () {
  $(this).click((e) => {
    if ($(".modal .pagination").length > 0 && $(e.target).data("export") !== "page") {
      $(".export-options").show()
      return false
    }

    const filename = $(".modal-title").first().text().toLowerCase().replace(/\s/gi, "_") + ".csv"
    tableToCSV($(".modal table"), filename, e.currentTarget)
    $(".export-options").hide()
  })
})

$.onmount("[data-action=close-export]", function () {
  $(this).click((e) => {
    e.preventDefault()
    $(".export-options").hide()
    $(".export-all-message").hide()
  })
})

$.onmount("[data-action=export-all]", function () {
  $(this).click(() => {
    $(".export-options").hide()
    $(".export-all-message").show()
  })
})
