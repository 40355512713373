# Relies on cropbox.js.  See https://github.com/hongkhanh/cropbox
class PjaxImageModal extends Modal
  constructor: (el) ->
    super el
    @$uploader = $("[data-pjax-uploader=#{@$el.data('pjax-modal-image-uploader')}]")
    @$uploaderForm = @$uploader.find('form')
    @$uploader.on 'image:updated', @afterUpdate
    @renderImageEditor()

  addEvents: ->
    super()

    $('[data-action=rotate-left]').click (e) => @rotateLeft(e)
    $('[data-action=rotate-right]').click (e) => @rotateRight(e)
    $('[data-action=zoom-in]').click (e) => @zoomIn(e)
    $('[data-action=zoom-out]').click (e) => @zoomOut(e)
    $('[data-action=upload-image]').click (e) => @save(e)

  open: ->
    $('body').append(@$overlay)
    $('body').addClass 'overflow-hidden'
    $('body').addClass('modal-active')
    @addEvents()
    @$overlay.fadeIn =>
      @$el.trigger 'modal:open'
    @

  close: (e) =>
    super e
    $('body').removeClass 'overflow-hidden'
    @$uploaderForm[0].reset()
    @

  renderImageEditor: =>
    imageBlob = @$uploaderForm.find('[type=file]')[0].files[0]

    reader = new FileReader()
    reader.onload = (e) =>
      image = @$el.find('#cropper-image')[0]
      image.src = e.currentTarget.result

      @cropper = new Cropper image, aspectRatio: 1, autoCropArea: 1

    reader.readAsDataURL imageBlob

  rotateLeft: =>
    @cropper.rotate(-90)

  rotateRight: =>
    @cropper.rotate(90)

  zoomIn: =>
    @cropper.zoom(.1)

  zoomOut: =>
    @cropper.zoom(-.1)

  save: (e) =>
    e.preventDefault()

    cropped_canvas = @cropper.getCroppedCanvas()
    cropped_canvas.toBlob (blob) =>
      $.when @$el.trigger('image:set', blob, cropped_canvas.toDataURL())
      .done =>
        @close()

  afterUpdate: =>
    @reloadTargetContainer()

window.PjaxImageModal = PjaxImageModal

$.onmount '[data-pjax-modal-image]', -> new PjaxImageModal(@)
