import { arc, easeCircle, interpolate, pie, scaleOrdinal, select } from "d3"
import { defaults, identity, isEmpty, pick } from "lodash"

import pickConfiguredDimensions from "../utils/pickConfiguredDimensions"
import pickChartOptionsOfNode from "../utils/pickChartOptionsOfNode"
import useOrAppendSVG from "../utils/useOrAppendSVG"

const DonutChartBaseConfig = Object.freeze({
  disableAnimation: false,
  colors: [],
  entries: [],
  innerRadius: 30,
})

const OptionKeys = Object.keys(DonutChartBaseConfig)

/** @private */
function configure(node, options) {
  const base = defaults(
    pickConfiguredDimensions(options, node),
    pick(options, OptionKeys),
    pick(pickChartOptionsOfNode(node), OptionKeys),
    DonutChartBaseConfig,
  )

  const radius = Math.min(base.width, base.height) / 2
  const color = scaleOrdinal().range(base.colors)

  return {
    ...base,
    arcOf: arc().innerRadius(base.innerRadius).outerRadius(radius),
    colorOf: (_entry, index) => color(index),
    radius,
  }
}

/** @private */
function maybeAppendOriginalHTMLAsForeignObject(selection, html, { radius, width, height }) {
  if (!html || isEmpty(html)) {
    return
  }

  selection
    .selectAll("foreignObject")
    .data([null])
    .enter()
    .append("foreignObject")
    .attr("x", 0.5 * width - 0.5 * radius)
    .attr("y", 0.5 * height - 0.5 * radius)
    .attr("width", `${radius}px`)
    .attr("height", `${radius}px`)
    .attr("dominantBaseline", "middle")
    .append("xhtml:body")
    .attr("class", "flex items-center justify-center")
    .style("text-align", "center")
    .style("flex-direction", "column")
    .style("height", "100%")
    .html(html)
}

/** @private */
function appendDonutGroup(selection, { arcOf, colorOf, entries, width, height }) {
  selection
    .selectAll("g")
    .data([null])
    .enter()
    .append("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`)
    .selectAll("path")
    // Maps entries into values that `arcOf` can accept and work with.
    .data(pie().value(identity).sort(null)(entries))
    .enter()
    .append("path")
    .attr("d", arcOf)
    .attr("fill", colorOf)
}

/** @private */
function maybeTransitionDonut(selection, { arcOf, disableAnimation, entries }) {
  if (disableAnimation) {
    return
  }

  const tweenAttrD = (datum) => {
    const interpolator = interpolate(datum.startAngle + 0.1, datum.endAngle)
    return (tweenVal) => arcOf({ ...datum, endAngle: interpolator(tweenVal) })
  }

  selection
    .selectAll("path")
    .attr("d", null)
    .transition()
    .ease(easeCircle)
    .duration(250)
    .delay((_datum, indexOfDatum) => indexOfDatum * 250)
    .attrTween("d", tweenAttrD)

  selection
    .selectAll("foreignObject")
    .style("opacity", 0)
    .transition()
    .delay(entries.length * 250 - 50)
    .duration(250)
    .style("opacity", 1)
}

/**
 * Mount a donut chart on a DOM node, or on a DOM node that matches a selector.
 *
 * The primary value, `entries`, is an array of primitive values. Each entry
 * takes up a slice of the donut proportional to its value and the sum of all
 * entries.
 *
 * Sets the color of each slice using a round-robin pattern. In the example
 * below, the last entry is shown with the color red since it has ran out of
 * options. Were there a fourth entry, its color would be green.
 *
 * @example
 *
 *     DonutChart('#my-donut', {
 *       entries: [40, 40, 20],
 *       colors: ['red', 'green'],
 *       innerRadius: 40,
 *       height: 128,
 *       width: 128
 *     });
 *
 *     //=>
 *     +--+-+----+
 *     |  |R|    |
 *     |  +-+-+  |
 *     |R |   |G |
 *     |  +--++  |
 *     |     |   |
 *     +-----+---+
 *
 * @param {string | HTMLElement | Node} nodeOrSelector
 * @param {object} options
 * @returns {d3.Selection} The selection that wraps your selector or node.
 */
function DonutChart(nodeOrSelector, options = {}) {
  const selection = select(nodeOrSelector)
  const node = selection.node()
  const config = configure(node, options)

  // Snapshot the inner HTML of the DOM element which we are mounting onto. We
  // do this so we can both clear the inner HTML and also use it within the SVG
  // we build.
  const html = node.innerHTML
  node.innerHTML = ""

  return useOrAppendSVG(selection, config)
    .call(maybeAppendOriginalHTMLAsForeignObject, html, config)
    .call(appendDonutGroup, config)
    .call(maybeTransitionDonut, config)
}

export default DonutChart
