# Pjax Setup
class Pjax
  @targetContainer: (containerID) ->
    "[data-pjax-container='#{containerID}']"

  @dataPjaxClick: (e) ->
    $dispatchEl = $(@).closest('[data-pjax-click]')
    containerName = $dispatchEl.data('pjax-click')
    selector = Pjax.targetContainer(containerName)
    options = { container: selector }
    if containerName == 'modal' ||
       containerName == 'modal-content' ||
       containerName == 'preview' ||
       $dispatchEl.data('pjax-push') == false
      options.push = false
      options.scrollTo = false
    if $dispatchEl[0].hasAttribute('data-pjax-type')
      options.type = $dispatchEl.data('pjax-type')
    if $dispatchEl[0].hasAttribute('data-pjax-fragment')
      options.fragment = selector
    $.pjax.click(e, options)
    $.pjax.xhr = null unless $dispatchEl.data('pjax-async') == false
    $dispatchEl.trigger('pjax:started')

  @render: (element, params = {}) ->
    dataAttribute = 'data-pjax-render'
    $element = $(element)
    url = $element.attr(dataAttribute)
    selector = "[#{dataAttribute}='#{url}']"

    options = { url: url, container: selector, push: false }
    options.scrollTo = false if $element.parents('.modal').length > 0
    options = $.extend(options, params)

    $.pjax(options)
    $.pjax.xhr = null

$.onmount '[data-pjax-click] a, a[data-pjax-click]', ->
  $(@).on 'click', Pjax.dataPjaxClick

$.onmount '[data-pjax-reload] a, a[data-pjax-reload]', ->
  $(@).on 'click', (e) ->
    e.preventDefault()
    $dispatchEl = $(this).closest('[data-pjax-reload]')
    containerName = $dispatchEl.data('pjax-reload')
    selector = Pjax.targetContainer(containerName)
    options = { container: selector }
    if $dispatchEl[0].hasAttribute('data-pjax-fragment')
      options.fragment = selector
    $.pjax.reload(options)

$.onmount 'form[data-pjax-submit]', ->
  $(@).on 'submit', (e) ->
    data = $(this).data()

    selector = Pjax.targetContainer(data.pjaxSubmit)
    options = $.extend(
      { container: selector, push: false },
      if ('pjaxFragment' of data) then fragment: selector else {},
      {
        type: data.pjaxType,
        replace: data.pjaxReplace,
        scrollTo: data.pjaxScrollTo,
        refreshData: data.refreshData,
      },
    )
    if (data.pjaxSubmit == 'modal-content' && data.followUpForm)
      options.customSuccess = -> $('.modal-overlay').scrollTop(0)

    if data.pjaxSubmitConfirm
      message = data.pjaxSubmitConfirm
      unless confirm(message)
        return e.preventDefault()

    $.pjax.submit(e, options)

$.onmount '[data-pjax-render]', ->
  Pjax.render(@)

$.onmount '[data-pjax-rerender]', ->
  $element = $(@)
  $element.click ->
    $element.closest('[data-pjax-container]').one 'pjax:end', ->
      containerName = $element.data('pjax-rerender')
      selector = Pjax.targetContainer(containerName)
      $container = $(selector)
      url = $container.data('pjax-render')
      if url != undefined
        $.pjax({
          url: url,
          container: selector,
          push: false,
        })
      else
        $container.trigger('pjax:rerender')

# Progress bar at top of page
NProgress.configure
  showSpinner: false,
  trickleRate: 0.05,
  trickleSpeed: $.pjax.defaults.timeout / 20
$(document).on('pjax:send.nprogress', NProgress.start)

$(document).on 'pjax:complete.nprogress', (xhr, options) ->
  NProgress.done()
  $.onmount()

$.pjax.defaults.timeout = 1200
$(document).on 'pjax:timeout', (e) ->
  # Prevent default timeout redirection behavior
  e.preventDefault()

$(document).on 'pjax:success', (e, data, status, xhr, options) ->
  # run "customSuccess" method passed to PJAX if it exists
  if(typeof options.customSuccess == 'function')
    options.customSuccess()

  return unless options.target?.tagName?.toUpperCase() == 'A'
  $link = $(options.target)
  return unless $link.parents('.tabs-container').length > 0
  $link.siblings('.active').removeClass('active')
  # for links in the 'more' dropdown
  $link.parents('.tabs-container').find('.active:not(.tab-dropdown-link)').removeClass('active')
  $link.addClass('active')

$(document).on 'pjax:error', (e, xhr, textStatus, error, options) ->
  e.preventDefault() # kills full page reloads
  xhr.abort()
  console.error('pjax:error - xhr aborted', xhr)

$(document).on 'pjax:beforeReplace', (e) ->
  # see: https://github.com/reactjs/react-rails#event-handling
  # see also: https://github.com/reactjs/react-rails/pull/490/files
  ReactRailsUJS?.unmountComponents(e.target)

$(document).on 'ready pjax:end', (e, xhr, options) ->
  container = e.target
  # We avoid re-mounting components if the pjax request was aborted.
  unless xhr?.statusText == 'abort'
    ReactRailsUJS?.mountComponents(container)

  # Reinitializes tinymce on new page content
  # see: https://github.com/defunkt/jquery-pjax#reinitializing-pluginswidget-on-new-page-content
  $tinymceEditors = $(container.querySelectorAll('.tinymce'))
  $tinymceEditors.each ->
    editor = tinymce.get("#{@id}")
    return unless editor
    configOptions = $.extend({},
      window.tinymceDefaults,
      {
        auto_focus: editor.options.get('auto_focus'),
        readonly: editor.options.get('readonly'),
      }
    )
    tinymce.remove(editor)
    tinymce.init(configOptions)

window.Pjax = Pjax
