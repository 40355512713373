class ColoredLabelEditor
  constructor: ($el) ->
    @$el = $el
    @_wireEvents()
    @_setUpColorPicker()

  show: =>
    @_topLevelContainer().on('click', (e) => @_checkClick(e)) if not @binded
    @binded = true
    @$el.show()

  hide: =>
    @$el.hide()

  _topLevelContainer: ->
    if $('.modal-overlay').length then $('.modal-overlay') else $('body')

  _wireEvents: =>
    @$el.on 'click', '.a-close', => @hide()
    @$el.on 'click', '[data-action=save]', (e) => @_onSave(e)

  _setUpColorPicker: =>
    @$el.find('[data-widget=color-palette]').colorPalette()

  _checkClick: (e) =>
    $target = $(e.target)
    isWidget = $target.className is 'colored-label-editor-wrapper'
    isInsideWidget = $target.parents('.colored-label-editor-wrapper').length
    isMyEditBtn = $target.closest('.actions')[0] == @$el.parent().find('.actions')[0]
    @hide() if !isWidget && !isInsideWidget && !isMyEditBtn

  _color: =>
    @$el.find('[data-widget=color-palette]').val()

  _onSave: (e) =>
    e.preventDefault()
    @$el.trigger 'colored-label-editor::save', color: @_color()
    @hide()

$.onmount '.with-color-picker', ->
  $btn = $(@)

  $colorPickerEl = $btn.parent().find('.colored-label-editor-wrapper')
  colorPickerWidget = new ColoredLabelEditor($colorPickerEl)
  $colorPickerEl.on 'colored-label-editor::save', (e, payload) ->
    $btn.css('background-color', payload.color)
    $btn.css('border-color', payload.color)

  $btn.on 'click', (e) ->
    e.preventDefault()
    colorPickerWidget.show()
