import React from "react"
import { createRoot } from "react-dom/client"
import { StoreProvider } from "v2/redux/StoreProvider"

import { VacancyRate } from "v2/react/components/dashboard/widgets/VacancyRate"

// Widgets currently using react
// @see app/serializers/widget_serializer.rb
// @see webpack/v2/components/dashboard.js
export const REACT_WIDGETS = ["vacancy_rate"]

const WIDGET_COMPONENTS = {
  vacancy_rate: VacancyRate,
}

REACT_WIDGETS.forEach((widget) => {
  $.onmount(`#react-widget__${widget}`, () => {
    const container = document.getElementById(`react-widget__${widget}`)
    const root = createRoot(container)

    const Widget = WIDGET_COMPONENTS[widget]

    if (!Widget) {
      // eslint-disable-next-line no-console
      console.error(`No React component found for widget: ${widget}`)
      return
    }

    root.render(
      <StoreProvider>
        <Widget />
      </StoreProvider>,
    )
  })
})
