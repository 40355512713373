# This adds handling for various row-level and table-level actions that can be
# associated with a pjax table.
$.onmount '.pjax-paginated-table', ->
  constants = {
    $tableContainer: $(@),
    targetContainerSelector: $(@).data('target-container'),
    $form: -> constants.$tableContainer.find('form'),
    $actionSelect: -> constants.$tableContainer.find('select#action-select'),
    $actionBar: -> constants.$tableContainer.find('section#action-bar'),
    $actionWrap: -> constants.$tableContainer.find('.action-wrap'),
    $selectedCount: -> constants.$tableContainer.find('#selected-count'),
    $checkboxes: -> constants.$tableContainer.find('input[type=checkbox]'),
  }

  functions = {
    selectClick: ->
      $option = $(@).find('option:selected')
      containerName = $option.data('pjax-click')
      if containerName
        constants.$form().attr('data-pjax-submit', 'modal')
        constants.$form().attr('action', $option.data('pjax-url'))
        $.onmount()
      else
        constants.$form().attr('action', $option.data('url'))

      constants.$form().submit()

      constants.$form().removeAttr('data-pjax-submit')
      $.onmount()
      $(@).find('option:first-child').prop('selected', true)

    openModal: ->
      $option = $(@)
      constants.$form().attr('data-pjax-submit', 'modal')
      constants.$form().attr('action', $option.data('pjax-url'))
      $.onmount()
      constants.$form().submit()
      constants.$form().removeAttr('data-pjax-submit')
      $.onmount()

    exportContent: ->
      $option = $(@)
      constants.$form().attr('action', $option.data('url'))
      constants.$form().submit()
      constants.$form().removeAttr('data-pjax-submit')
      $.onmount()

    toggleAll: ->
      newVal = $(@).prop('checked')
      constants.$checkboxes().prop('checked', newVal)

    countSelected: ->
      count = constants.$tableContainer.find('input[type=checkbox]:checked')
        .not('[data-action=toggle-all]').length
      constants.$selectedCount().text(count)
      if count > 0 && (constants.$actionSelect().attr('disabled') || constants.$actionBar().hasClass('translate-y-110'))
        constants.$actionSelect().closest('.select').removeClass('disabled')
        constants.$actionSelect().attr('disabled', false)

        constants.$actionBar().closest('section').removeClass('translate-y-110 scale-98 mt-[-3rem]')
        constants.$actionBar().closest('section').css('transform', 'translateY(12px)')

      if count == 0 && (!constants.$actionSelect().attr('disabled') || constants.$actionBar())
        constants.$actionSelect().closest('.select').addClass('disabled')
        constants.$actionSelect().attr('disabled', true)

        constants.$actionBar().closest('section').css('transform', 'translateY(110%)')
        constants.$actionBar().closest('section').addClass('translate-y-110 scale-98 mt-[-3rem]')

    clearFilters: ->
      $(@).hide()
      targetContainer = Pjax.targetContainer(constants.targetContainerSelector)
      $.pjax
        url: window.location.pathname,
        container: targetContainer,
        fragment: targetContainer,
        push: false,
        replace: true,
  }

  $(@).on 'change', 'select#action-select', functions.selectClick
  $(@).on 'click', '[data-action=toggle-all]', functions.toggleAll
  $(@).on 'click', '.a-open-modal', functions.openModal
  $(@).on 'click', '.a-export', functions.exportContent
  $(@).on 'change', 'input[type=checkbox]', functions.countSelected
  $(document).on 'pjax:end', functions.countSelected
  $(@).on 'click', '[data-action=clear-filter]', functions.clearFilters

  functions.countSelected()
