vars = {
  $files: undefined,
  $formId: undefined
}

$.onmount "form[id*='new_benefit_plan']", ->
  constants = {
    $form: $(@),
    newFileUploadTemplate: _.template($('#new-file-upload-template').html()),
    formFileUploadTemplate: _.template($('#new-form-file-upload-template').html()),
    newLinkTemplate: _.template($('#new-link-template').html()),
    formLinkTemplate: _.template($('#form-link-template').html()),
  }

  functions = {
    enableSubformItems: ->
      $('[data-action=add-file-upload]').on 'click', functions.addFileForm
      $('[data-action=add-link]').on 'click', functions.addLinkForm
      $('.list-group-subform-item').each ->
        $(document).trigger 'append-subform', [$(@), 'exist']

    addFileForm: ->
      $fileContainer = $(@).closest('.file-container')
      $fileContainer.find('.add-btn').hide()
      $fileContainer.find('.list-group').append(constants.newFileUploadTemplate)
      $item = $fileContainer.find('.list-group-subform-item:last')
      $item.find('.list-group-item').append(constants.formFileUploadTemplate)
      $(document).trigger 'append-subform', [$item, 'new']

    addLinkForm: ->
      $linkContainer = $(@).closest('.link-container')
      $linkContainer.find('.add-btn').hide()
      $linkContainer.find('.list-group').append(constants.newLinkTemplate)
      $item = $linkContainer.find('.list-group-subform-item:last')
      $item.find('.list-group-item').append(constants.formLinkTemplate)
      $(document).trigger 'append-subform', [$item, 'new']

    saveFiles: ->
      vars.$formId = constants.$form.attr('id')
      vars.$files = constants.$form.find('input[type=file]').clone()

    setFiles: ->
      if vars.$files && vars.$formId == constants.$form.attr('id')
        constants.$form.find('input[type=file]').each (index) ->
          $hiddenFields = $(@).parent()
          $(@).replaceWith(vars.$files[index])
          if ($(vars.$files[index]).val() != '')
            $hiddenFields.find('[name="benefit_plan[file_uploads][][file]"][type=hidden]').remove()

    beforeSubmit: (e) ->
      functions.saveFiles()

    disableSubmit: ->
      $submitBtn = $('.modal [data-action=save]')
      $submitBtn.addClass('disabled')
      $submitBtn.attr('disabled', 'disabled')

    enableSubmit: ->
      if $('.modal .edit').length == 0
        $submitBtn = $('.modal [data-action=save]')
        $submitBtn.removeClass('disabled')
        $submitBtn.attr('disabled', false)
  }

  functions.setFiles()
  functions.enableSubformItems()

  $(document).on 'submit', @, functions.beforeSubmit
  $(document).on 'subform:save', '.list-group-subform-item', functions.enableSubmit
  $(document).on 'subform:close', '.list-group-subform-item', functions.enableSubmit
  $(document).on 'subform:open', '.list-group-subform-item', functions.disableSubmit
