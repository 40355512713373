$.onmount '#periodic', ->
  functions = {
    triggerAccrualFrequency: ->
      $('.accrual-date').hide()
      accrualFrequency = $('#pto_type_accrual_frequency option:selected').val()
      $(".#{accrualFrequency}-accrual-day").show()

    weeklyPeriod: ->
      accrualTiers = $('.first-accrual-tier [name="pto_type[accrual_tiers][0][value]"]').val()
      periodAmount = accrualTiers / functions.numAccruals().toFixed(4)
      $('#period-amount').text(periodAmount.toFixed(4))
      $('#amount-accrual-frequency').text(functions.accrualFrequency())

    numAccruals: ->
      switch functions.accrualFrequency()
        when 'weekly' then 52
        when 'biweekly' then 26
        when 'monthly' then 12
        when 'bimonthly' then 24
        else 1

    accrualFrequency: ->
      $('#pto_type_accrual_frequency option:selected').val()
  }

  $(@).on 'change', '[name="pto_type[accrual_frequency]"]', functions.triggerAccrualFrequency
  $(@).on 'change', '[name="pto_type[accrual_frequency]"]', functions.weeklyPeriod
  functions.triggerAccrualFrequency()

  $(@).on 'input', '[name="pto_type[accrual_tiers][0][value]"]', functions.weeklyPeriod
  functions.weeklyPeriod()
