$.onmount '.stars', ->
  $labels = $(this).find('label')
  $labels.on 'mouseup', ->
    $radio = $(this).prev()
    if $radio.prop('checked')
      setTimeout ->
        $radio.siblings('input[value=0]').click()
      , 5

    setTimeout ->
      if $radio.parents('.module-card-list-item').length > 0
        $form = $radio.closest('form')
        $.ajax
          url: $form.prop('action'),
          type: 'PUT',
          data: $form.serialize()
    , 10
