window.escapeForCSV = (text) ->
  text = text.replace(/"/g, '""')
  text = "'" + text if /^[=\+\-@]/.test(text)
  text = "\"#{text}\"" if /[",]/.test(text)

  text

window.tableToCSV = ($table, filename, link, options) ->
  $rows = $table.find('tr:has(td), tr:has(th)')
  rows = $rows.map (i, row) ->
    $row = $(row)
    $columns = $row.find('td,th')
    columns = $columns.map (j, column) ->
      $column = $(column)
      text = $column.children(':not(.no-print)').map( -> $(this).text()).get().join(', ') || $column.text()
      text = text.trim().replace(/\s*\n\s*/, ' ')
      text = text.replace('–', '-')

      unless $column.hasClass 'no-print'
        escapeForCSV(text)

    columns.get().join(',')

  csv = rows.get().join('\r\n')
  csvData = 'data:application/csv;charset=utf-8,' + encodeURIComponent(csv)

  $(link)
    .attr
      'download': filename,
      'href': csvData
