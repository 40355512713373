class PjaxImageUploader
  constructor: (el) ->
    @$el = $(el)
    @$image = @$el.find('img')
    @$imageAction = @$el.find('[data-action=edit-image]')
    @$form = @$el.find('form')
    # form will be present only if the person has the ability to edit the image
    return unless !!@$form[0]
    @$fileInputField = @$form.find('[type=file]')

    @modalUrl = @$el.data('pjax-uploader-modal-url')
    @imageUrlKey = @$el.data('pjax-uploader-image-url-key')
    unless @modalUrl && @imageUrlKey
      throw new Error('data attributes missing for image uploader')

    @$imageAction.click @onActionClick
    @$fileInputField.change @onFileChange

  onActionClick: =>
    @$fileInputField.click()

  onFileChange: (e) =>
    if !@$fileInputField.val()
      e.preventDefault()
      @fileError()
    else
      file = @$fileInputField[0].files[0]

      if (file.type.match('image.*'))
        @openModal()
      else
        e.preventDefault()
        @notImageFile()

  notImageFile: =>
    alert('must be an image')

  fileError: =>
    @$form[0].reset()
    Sentry?.captureMessage 'Trying to upload an image without an actual file.'
    @$el.trigger 'image:file:error', [@]

  openModal: =>
    $.pjax(
      url: @modalUrl
      container: '[data-pjax-container=modal]'
      push: false
      scrollTo: false
    ).then =>
      $('[data-pjax-modal-image]').on 'image:set', @upload

  upload: (_e, blob, dataURL) =>
    formData = new FormData()
    name = @$fileInputField.val().split(/(\\|\/)/g).pop() || 'blob.png'
    formData.append(@$fileInputField.attr('name'), blob, name)

    if @$el.data('pjax-uploader-save') == false
      @$image.attr('src', dataURL)
      return

    $.when $.pjax(
      container: "[data-pjax-container='modal-content']"
      replace: false,
      push: false,
      type: 'PUT',
      url: @$form.attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      timeout: 3000
    )
    .done =>
      @$el.trigger('image:updated')

$.onmount '[data-pjax-uploader]', -> new PjaxImageUploader(@)
