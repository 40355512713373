$.onmount Modal.selector('settings-integrations'), ->
  constants = {
    $modal: $(@),
    containerSelector: Pjax.targetContainer('modal-content'),
  }

  functions = {
    deactivate: (e) ->
      e.preventDefault()
      url = $(@).attr('href')

      if window.confirm($(@).data('confirm'))
        $.when $.pjax(
          method: 'delete',
          url: url,
          container: constants.containerSelector,
          fragment: constants.containerSelector,
          replace: false,
          push: false,
        )
        .done =>
          constants.$modal.find('[data-action=close]').click()
    reloadContainer: (_e, modal) ->
      modal.reloadTargetContainer().done(modal.addAnother)
  }

  constants.$modal.find('[data-action=delete]').on 'click', functions.deactivate
  $(@).on 'modal:save', functions.reloadContainer
  $(@).on 'modal:close', functions.reloadContainer
