$.onmount Modal.selector('people-filter'), ->
  constants = {
    $modal: $(@),
    $criterionList: $(@).find('.criterion-list'),
    $form: $(@).find('form'),
    $submit: $(@).find('[data-action=submit]'),
  }

  functions = {
    columnType: (value) ->
      switch value
        when 'hire_date', 'terminated_at', 'birth_date'
          'date'
        when 'schedule_id'
          'schedule'
        when 'ssn_ciphertext'
          'ssn'
        when 'employee_status_id'
          'status'
        when 'termination_reason'
          'termination-reason'
        when 'gender'
          'gender'
        when 'is_hispanic'
          'is-hispanic'
        when 'marital_status'
          'marital-status'
        when 'filing_status'
          'filing-status'
        when 'decline_withholding'
          'decline-withholding'
        when 'employee_type_id'
          'employee-type'
        when 'base_pay_type'
          'pay-type'
        when 'base_pay'
          'currency'
        when 'hours_per_week'
          'quantifiable'
        else
          'text'

    hideInput: ($el) ->
      $el.addClass('hidden')
      $el.find('select, input').attr('disabled', true)

    showInput: ($el) ->
      $el.removeClass('hidden')
      $el.find('select, input').attr('disabled', false)

    columnChanged: ->
      selected = $(@).find('option:selected').val()
      $criterion = $(@).closest('.criterion')
      $visibleCondition = $criterion.find('.criterion-condition:not(.hidden)')
      $criterionValue = $criterion.find('.criterion-value:not(.hidden)')

      type = functions.columnType(selected)
      type = switch type
        when 'status', 'termination-reason', 'gender', 'is-hispanic', \
             'marital-status', 'filing-status', 'decline-withholding', \
             'employee-type', 'pay-type'
          'select'
        when 'date', 'schedule', 'ssn', 'text', 'quantifiable', 'currency'
          type

      if !$visibleCondition.hasClass("criterion-#{type}-condition")
        $selectedCondition = $criterion.find(".criterion-#{type}-condition")
        functions.hideInput($visibleCondition)
        functions.showInput($selectedCondition)
        $selectedCondition.trigger('change')

        if $criterionValue
          functions.hideInput($criterionValue)

    conditionChanged: ->
      selected = $(@).find('option:selected').val()
      $criterion = $(@).closest('.criterion')
      $visibleValue = $criterion.find('.criterion-value:not(.hidden)')

      selectedColumn = $criterion.find('.criterion-column:not(.hidden) option:selected').val()

      switch selected
        when 'blank', 'not_blank'
          functions.hideInput($visibleValue)
        else
          type = functions.columnType(selectedColumn)
          return if type == 'ssn'

          $selectedValue = $criterion.find(".criterion-#{type}-value")

          if $visibleValue != $selectedValue
            functions.hideInput($visibleValue)
            functions.showInput($selectedValue)

    addFilterRow: ->
      index = constants.$criterionList.find('.criterion').length + 1
      $filterRow = _.template($('#filter-row-template').html())
      constants.$criterionList.append($filterRow(index: index))

    toggleBtn: (e) ->
      e.preventDefault()
      return if $(@).hasClass('active')
      $group = $(@).closest('.operator-group')
      $group.find('.active').removeClass('active')
      $(@).addClass('active')
      $group.find('[name*="operator"]').val($(@).data().value)

    modalSubmit: ->
      url = window.location.pathname + '?' + constants.$form.find('input, select').serialize()
      keepers = ['chart_section', 'list', 'search']
      params = new URLSearchParams(window.location.search)
      keepers.forEach (key) ->
        paramsValue = params.get(key)
        url += "&#{key}=#{paramsValue}" if paramsValue

      targetContainer = Pjax.targetContainer('people-index')
      $.pjax
        url: url,
        container: targetContainer,
        fragment: targetContainer,
        push: false,
        replace: true,

      constants.$modal.find('[data-action=close]').trigger('click')
  }

  constants.$submit.on 'click', functions.modalSubmit
  $(@).on 'change', '.criterion-column', functions.columnChanged
  $(@).on 'change', '.criterion-condition', functions.conditionChanged
  $(@).on 'click', '[data-action=add-criterion]', functions.addFilterRow
  $(@).on 'click', '[data-action=toggle-button]', functions.toggleBtn
