$.onmount "form[id*='new_company_document']", ->
  $fileInput = $(@).find('#file-input-group')
  $required = $(@).find('select#company_document_required')
  $requiredSection = $(@).find('[data-section=required]')
  $specificPositionsSection = $(@).find('[data-section=specific-positions]')
  $specificLocationsSection = $(@).find('[data-section=specific-locations]')
  $specificEmployeesSection = $(@).find('[data-section=specific-employee-types]')
  $customRequiredMonths = $(@).find('select#company_document_custom_required_months')
  $startingSection = $(@).find('[data-section=starting]')
  $datepicker = $(@).find('[data-widget=monthdaypicker]')

  @picker = new Pikaday
    field: $datepicker[0]
    format: 'MMM DD'
    defaultDate: 'Jan 01'
    setDefaultDate: true
    theme: 'hide-year'

  $datepicker.prop('picker', @picker)

  $fileInput.find('.input-file').on 'click', ->
    $fileInput.find('input[type=file]').click()

  $fileInput.find('input[type=file]').on 'change', ->
    unless $('#company_document_title').val()
      filename = $fileInput.find('input[type=file]').val().split('\\').pop()
      $('#company_document_title').val(filename)

  $required.on 'change', ->
    if ($required.find('option:selected').val() == 'true')
      $requiredSection.removeClass('hidden')
    else
      $requiredSection.addClass('hidden')

  $requiredSection.find("input[type=radio][name='company_document[positions_required_for]']").on 'change', ->
    if (@.value == 'positions')
      $specificPositionsSection.removeClass('hidden')
    else
      $specificPositionsSection.addClass('hidden')

  $requiredSection.find("input[type=radio][name='company_document[locations_required_for]']").on 'change', ->
    if (@.value == 'locations')
      $specificLocationsSection.removeClass('hidden')
    else
      $specificLocationsSection.addClass('hidden')

  $requiredSection.find("input[type=radio][name='company_document[employees_required_for]']").on 'change', ->
    if (@.value == 'employee_types')
      $specificEmployeesSection.removeClass('hidden')
    else
      $specificEmployeesSection.addClass('hidden')

  $customRequiredMonths.on 'change', ->
    if ($customRequiredMonths.find('option:selected').val() == 'once')
      $startingSection.addClass('hidden')
    else
      $startingSection.removeClass('hidden')
