keyMap =
  9: 'tab'
  27: 'esc'
  37: 'left'
  39: 'right'
  13: 'enter'
  38: 'up'
  40: 'down'
  91: 'command'
  93: 'command'
  16: 'shift'
  17: 'control'
  18: 'alt'

class Keyboard
  @keyMatchesName: (code, name) ->
    keyMap[code] == name

  @getKey: (code) ->
    keyMap[code] || null

window.Keyboard = Keyboard
