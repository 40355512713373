$.onmount Modal.selector('salary'), ->
  $(@).on 'modal:save', (_e, modal) ->
    window.BudgetTable.tableEvents(this)
    modal.reloadTargetContainer()

  return if $('.modal-title').text() == 'Delete'

  window.BudgetTable.tableEvents(this)

  $(@).on 'modal:error', ->
    window.BudgetTable.tableEvents(this)
