# Utility methods
window.App = window.App || {}

class App.Helpers
  # https://stackoverflow.com/questions/12006095/javascript-how-to-check-if-character-is-rtl
  @isRTL: (s) ->
    ltrChars = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF'
    rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC'
    rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']')
    return rtlDirCheck.test(s)

  ### l10n and i18n helpers ###

  # Returns an object that describes how monetary values are formatted for the
  # given locale and currency. Uses fallback locale code and currency code.
  #
  # This can be useful for parsing currency values into proper numbers.
  #
  # @example
  #
  #   App.Helpers.explainCurrency(currency: 'EUR', locale: 'fr')
  #   #=> { ..., decimal: ',', separator: ' ', symbol: '€', ... }
  @explainCurrency: (options = {}) ->
    locale = options.locale || window.gon?.currency_locale || 'en'
    currency = options.currency || window.gon?.currency_code || 'USD'

    # Check cache for currency details
    @cache ?= {}
    if @cache["#{locale}-#{currency}"]
      return @cache["#{locale}-#{currency}"]

    formatter =
      new Intl.NumberFormat locale,
        style: 'currency'
        currency: currency

    parts = formatter.formatToParts(82890.31)

    @cache["#{locale}-#{currency}"] =
      formatter: formatter
      currency: currency
      locale: locale
      symbol: parts.find((val) -> val.type == 'currency')?.value
      decimal: parts.find((val) -> val.type == 'decimal')?.value
      separator: parts.find((val) -> val.type == 'group')?.value
      demo:
        str: formatter.format(82890.31)
        arr: formatter.formatToParts(82890.31)

  # Returns a number that represents `val`, which should be a string. Forwards
  # numerics. Dies painfully with `null` or `undefined`. Or it just forwards the
  # despair back to the caller.
  #
  # Currency and locale can be provided as options, otherwise this falls back to
  # the settings in `window.gon`. Note that the first example below assumes an
  # account with locale 'fr' and currency 'EUR'.
  #
  # @example
  #
  #   parseCurrency('82 890,31 €') #=> 82890.31
  #   parseCurrency('$500.00', { locale: 'en', currency: 'USD' }) #=> 500
  @parseCurrency: (val, options = {}) ->
    return val unless val?
    return val if typeof val == 'number'
    rval = val

    details = @explainCurrency options
    regex = new RegExp "[^0-9#{details.decimal || ''}]", 'g'
    if @isRTL(val)
      rtlRegex = new RegExp('[\u0591-\u07FF]|[\uFB1D-\uFDFD]|[\uFE70-\uFEFC]', 'g')
      rval = val.replace('د.إ', '').replace(rtlRegex, '')
    nextStr = rval.replace(regex, '')
    return Number(nextStr) if details.decimal == '.' || details.decimal == ''

    Number nextStr.replace(details.decimal, '.')

  # Formats `val`, provided it is a number or a string representing a number, as
  # a currency.
  #
  # When +trailing+ is true, whole numbers will display zeros as their fraction
  # digits. Otherwise, for whole numbers, fraction digits will be dropped.
  #
  # @example
  #
  #   formatCurrency(50020.1) #=> 50 020,10 €
  #   formatCurrency(50020.0) #=> 50 020 €
  #   formatCurrency(50020.0, trailing: true) #=> 50 020,00 €
  @formatCurrency: (val, options = {}) ->
    return '' unless val? && val != ''
    return val if Number.isNaN(Number(val))

    locale = options.locale || window.gon?.currency_locale || 'en'
    uniqueSymbol = options.uniqueSymbol || window.gon?.useUniqueCurrencySymbol || false
    fmtOptions =
      style: 'currency'
      currency: options.currency || window.gon?.currency_code || 'USD'
    symbol = @symbolFor(fmtOptions.currency, uniqueSymbol)

    if !options.trailing && Number.isInteger(Number(val))
      fmtOptions.minimumFractionDigits = 0
      fmtOptions.maximumFractionDigits = 0
      fmtOptions.trailingZeroDisplay = 'stripIfInteger'

    parts = new Intl.NumberFormat(locale, fmtOptions).formatToParts(val)
    parts = parts.filter((val) -> val.type != 'currency') if options.omitSymbol
    currency_i = 0
    parts = parts.map((val, i) ->
      if val.type == 'currency'
        currency_i = i + 1
        return { type: 'currency', value: symbol }
      else if val.type == 'literal' && val.value.trim().length == 0 && currency_i > 0
        # The ruby number formatter does not add a space here
        return { type: 'literal', value: '' }
      else
        return val
    )
    parts.map((val) -> val.value).join('').trim()

  ### File helpers ###

  # Fetches path to assets
  #
  # @example
  #
  #   App.Helpers.imagePath('logo.png') #=> '/static-assets/logo.png'
  @imagePath: (filename) ->
    window.assets[filename] || "/static-assets/#{filename}"

  @symbolFor: (currencyCode, unique) ->
    definition = App.Symbols.currencyDefinitions()[currencyCode]
    hasDisambig = definition.disambiguation != null
    symbol = if unique && hasDisambig then definition.disambiguation else definition.symbol
