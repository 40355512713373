Subform = window.Subform

$(document).on 'append-subform', (_e, item, type) ->
  if $(item).is(Subform.selector('file-upload'))
    vars = {
      $fileInputClone: undefined,
    }

    constants = {
      $item: $(item),
      $fileContainer: $(item).closest('.file-container'),
      $showFileUploadTemplate: $('#show-file-upload-template'),
      $newFormFileUploadTemplate: $('#new-form-file-upload-template'),
      $editFormFileUploadTemplate: $('#edit-form-file-upload-template'),
      allowedFileTypes: [
        'jpg', 'jpeg', 'gif', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt',
        'pptx', 'txt', 'rtf', 'odt', 'ods', 'odp', 'tif', 'tiff', 'bmp', 'csv',
      ],
      $chooseFileBtn: -> $(item).find('.input-file .btn--large'),
      $fileInputStub: -> $(item).find('[name="benefit_plan[file_uploads][][file]"][type=hidden]'),
      $fileInput: -> $(item).find('[name="benefit_plan[file_uploads][][file]"][type=file]'),
      $fileUploadTitle: -> $(item).find('[name="benefit_plan[file_uploads][][title]"]'),
      $fileUploadId: -> $(item).find('[name="benefit_plan[file_uploads][][id]"]'),
      $fileUploadSubtitle: -> $(item).find('.subtitle'),
      $fileUploadPreview: -> $(item).find('.preview'),
      $fileUploadCurrentFile: -> $(item).find('[data-pjax-click=preview]'),
      $listGroupItem: -> $(item).find('.list-group-item'),
    }

    functions = {
      removeSubformHandlers: ->
        constants.$item.off 'subform:close'
        constants.$item.off 'subform:save'

      initSubform: ->
        constants.$chooseFileBtn().on 'click', functions.fileBtnClick
        constants.$fileInput().on 'change', functions.uploadFile
        constants.$item.on 'subform:close', functions.close
        constants.$item.on 'subform:save', functions.submit

      fileBtnClick: ->
        constants.$fileInput().trigger('click')

      uploadFile: ->
        functions.clearErrors()
        filename = constants.$fileInput().val().split('\\').pop()
        extension = filename.split('.').pop()

        if ($.inArray(extension, constants.allowedFileTypes) == -1)
          $inputGroup = constants.$item.find('[data-subform-field=file] .input-group')
          $inputGroup.addClass('form-error')
          $inputGroup.children('label').after("<div class='input-help-text form-error-message'>Invalid file type</div>")
          constants.$fileInput().val(null)
          constants.$chooseFileBtn().find('.btn-name').text('Choose File')
        else
          constants.$chooseFileBtn().find('.btn-name').text(filename)
          if constants.$fileUploadTitle().last().val().length == 0
            constants.$fileUploadTitle().val(filename)

      clearErrors: =>
        constants.$item.find('.form-error').removeClass('form-error')
        constants.$item.find('.form-error-message').remove()

      close: ->
        if constants.$item.hasClass('new')
          constants.$item.remove()
          functions.showBtn()
          constants.$item.removeClass('new')
        else
          constants.$fileInput().replaceWith(vars.$fileInputClone)

          constants.$listGroupItem().empty()
          title = constants.$fileUploadTitle().val()

          if constants.$fileInput().val() != ''
            subtitle = constants.$fileInput().val().split('\\').pop()
            constants.$fileUploadSubtitle().data('subtitle', subtitle)
          else
            subtitle = constants.$fileUploadSubtitle().data().subtitle
          constants.$listGroupItem().append(
            _.template(constants.$showFileUploadTemplate.html())(title: title, subtitle: subtitle)
          )

          $dropdownLinks = constants.$item.find('.dropdown .dropdown-link')
          $dropdownLinks.each -> $(@).on 'click', (e) -> DropdownMenu.handleClick(e)
          DropdownMenu.handleVisibility(constants.$item.closest('.modal'))
          constants.$item.find('[data-action=edit-file]').on 'click', functions.edit
          constants.$item.find('[data-action=delete-file]').on 'click', functions.delete

          if constants.$fileUploadPreview().data()
            constants.$fileUploadCurrentFile().attr('href', constants.$fileUploadPreview().data().url)
            constants.$fileUploadCurrentFile().on 'click', window.Pjax.dataPjaxClick
          else
            constants.$fileUploadCurrentFile().attr('href', '#')

          functions.removeSubformHandlers()

      showBtn: ->
        constants.$fileContainer.find('.add-btn').show()

      hideBtn: ->
        constants.$fileContainer.find('.add-btn').hide()

      edit: ->
        vars.$fileInputClone = constants.$fileInput().clone()
        title = constants.$fileUploadTitle().val()
        url = undefined

        if constants.$fileUploadPreview().data()
          url = constants.$fileUploadPreview().data().url
        constants.$listGroupItem().empty()

        if constants.$fileUploadId().length > 0 && constants.$fileInputStub().length > 0
          subtitle = constants.$fileUploadSubtitle().data().subtitle
          constants.$listGroupItem().append(
            _.template(
              constants.$editFormFileUploadTemplate.html()
            )(subtitle: subtitle)
          )
        else
          constants.$listGroupItem().append(
            _.template(constants.$newFormFileUploadTemplate.html())
          )

        if url
          constants.$fileUploadCurrentFile().attr('href', url)
          constants.$fileUploadCurrentFile().on 'click', window.Pjax.dataPjaxClick
        else
          constants.$fileUploadCurrentFile().attr('href', '#')

        constants.$fileUploadTitle().val(title)
        functions.initSubform()
        constants.$item.trigger('subform:open')

      delete: ->
        constants.$item.remove()

      submit: ->
        title = constants.$fileUploadTitle().last().val()

        if constants.$fileInput().val() != ''
          subtitle = constants.$fileInput().val().split('\\').pop()
          constants.$fileUploadSubtitle().data('subtitle', subtitle)
        else
          subtitle = constants.$fileUploadSubtitle().data().subtitle

        constants.$fileUploadTitle().val(title)
        constants.$listGroupItem().empty()
        constants.$listGroupItem().append(
          _.template(
            constants.$showFileUploadTemplate.html()
          )(title: title, subtitle: subtitle)
        )

        $dropdownLinks = constants.$item.find('.dropdown .dropdown-link')
        $dropdownLinks.each -> $(@).on 'click', (e) -> DropdownMenu.handleClick(e)
        DropdownMenu.handleVisibility(constants.$item.closest('.modal'))
        constants.$item.find('[data-action=edit-file]').on 'click', functions.edit
        constants.$item.find('[data-action=delete-file]').on 'click', functions.delete

        if constants.$fileInput().val() == ''
          url = constants.$fileUploadPreview().data().url
          constants.$fileUploadCurrentFile().attr('href', url)
          constants.$fileUploadCurrentFile().on 'click', window.Pjax.dataPjaxClick
        else
          constants.$fileUploadCurrentFile().attr('href', '#')

        functions.showBtn()
        constants.$item.removeClass('new')
        constants.$fileInputStub().remove()
        functions.removeSubformHandlers()
    }

    functions.initSubform()
    if type == 'new'
      functions.hideBtn()
      constants.$item.addClass('new')
      constants.$item.trigger('subform:open')
    else if type == 'exist'
      constants.$item.find('[data-action=edit-file]').on 'click', functions.edit
      constants.$item.find('[data-action=delete-file]').on 'click', functions.delete
