Subform = window.Subform

$(document).on 'append-subform', (_e, item, type) ->
  if $(item).is(Subform.selector('link'))
    constants = {
      $item: $(item),
      $linkContainer: $(item).closest('.link-container'),
      $showLinkTemplate: $('#show-link-template'),
      $fileLinkTemplate: $('#form-link-template'),
      $linkTitle: -> $(item).find('[name="benefit_plan[links][][title]"]').last(),
      $linkUrl: -> $(item).find('[name="benefit_plan[links][][url]"]').last(),
      $linkUpdatedAt: -> $(item).find('[name="benefit_plan[links][][updated_at]"]'),
      $listGroupItem: -> $(item).find('.list-group-item'),
    }

    functions = {
      removeSubformHandlers: ->
        constants.$item.off 'subform:close'
        constants.$item.off 'subform:save'

      initSubform: ->
        constants.$item.on 'subform:close', functions.close
        constants.$item.on 'subform:save', functions.submit

      close: ->
        if constants.$item.hasClass('new')
          constants.$item.remove()
          functions.showBtn()
          constants.$item.removeClass('new')
        else
          constants.$listGroupItem().empty()
          title = constants.$linkTitle().val()
          url = constants.$linkUrl().val()
          constants.$listGroupItem().append(_.template(constants.$showLinkTemplate.html())(title: title, url: url))

          $dropdownLinks = constants.$item.find('.dropdown .dropdown-link')
          $dropdownLinks.each -> $(@).on 'click', (e) -> DropdownMenu.handleClick(e)
          DropdownMenu.handleVisibility(constants.$item.closest('.modal'))
          constants.$item.find('[data-action=edit-link]').on 'click', functions.edit
          constants.$item.find('[data-action=delete-link]').on 'click', functions.delete

          functions.removeSubformHandlers()

      showBtn: ->
        constants.$linkContainer.find('.add-btn').show()

      hideBtn: ->
        constants.$linkContainer.find('.add-btn').hide()

      edit: ->
        title = constants.$linkTitle().val()
        url = constants.$linkUrl().val()
        constants.$listGroupItem().empty()
        constants.$listGroupItem().append(_.template(constants.$fileLinkTemplate.html()))
        constants.$linkTitle().val(title)
        constants.$linkUrl().val(url)
        functions.initSubform()
        constants.$item.trigger('subform:open')

      delete: ->
        constants.$item.remove()

      submit: ->
        title = constants.$linkTitle().val()
        url = constants.$linkUrl().val()
        constants.$listGroupItem().empty()
        constants.$listGroupItem().append(_.template(constants.$showLinkTemplate.html())(title: title, url: url))
        constants.$linkTitle().val(title)
        constants.$linkUrl().val(url)
        constants.$linkUpdatedAt().val(moment().format('M/DD/YYYY'))

        $dropdownLinks = constants.$item.find('.dropdown .dropdown-link')
        $dropdownLinks.each -> $(@).on 'click', (e) -> DropdownMenu.handleClick(e)
        DropdownMenu.handleVisibility(constants.$item.closest('.modal'))
        constants.$item.find('[data-action=edit-link]').on 'click', functions.edit
        constants.$item.find('[data-action=delete-link]').on 'click', functions.delete

        functions.showBtn()
        constants.$item.removeClass('new')
        functions.removeSubformHandlers()
    }

    functions.initSubform()
    if type == 'new'
      functions.hideBtn()
      constants.$item.addClass('new')
      constants.$item.trigger('subform:open')
    else if type == 'exist'
      constants.$item.find('[data-action=edit-link]').on 'click', functions.edit
      constants.$item.find('[data-action=delete-link]').on 'click', functions.delete
