$.onmount "form[id*='profile-settings']", ->
  constants = {
    $this: $(@),
    $enabled: $(@).find('[name="company[settings][profile_change_notifications][enabled]"]'),
    $recipientsItem: $(@).find('[name="company[settings][profile_change_notifications][recipients][]"]'),
    $recipients: $(@).find('.recipients'),
  }

  functions = {
    toggleEnabled: ->
      enabled = constants.$this.find('[name="company[settings][profile_change_notifications][enabled]"]:checked').val() == 'true'
      if enabled
        constants.$recipients.removeClass('disabled')
        constants.$recipientsItem.prop('disabled', false)
      else
        constants.$recipientsItem.prop('checked', false)
        constants.$recipientsItem.prop('disabled', true)
        constants.$recipients.addClass('disabled')
  }

  constants.$enabled.on 'change', functions.toggleEnabled
