$.onmount 'form.new_pto_type', ->
  functions = {
    setAllBalances: ->
      globalBalance = $('#global-value').val()
      $balanceFields = $('[name$=\\[balance\\]]')

      $balanceFields.val(globalBalance)
  }

  $(@).find('[data-action=set]').on 'click', functions.setAllBalances
