class FileUploadTable
  constructor: (file_field) ->
    @data = {}

    @file_field = $(file_field)
    @container = @file_field.parent('.input-file')
    @table = @container.siblings('.table')

    @type = @file_field.data('uploadable-type')
    @type_lower = @type.toLowerCase()

    if @file_field.data('uploadable-id')
      @data =
        file_uploadable_type: @type
        file_uploadable_id: @file_field.data('uploadable-id')

    @addEvents()

  addEvents: ->
    @table.find('[data-action=delete]').click (e) =>
      e.preventDefault()
      $link = $(e.currentTarget)
      $input = @container.find("input[value=#{$link.data('id')}]")
      $input.attr('name', "#{@type_lower}[deleted_file_upload_ids][]")
      $link.parents('tr').remove()

    @file_field.fileupload
      dataType: 'json'
      formData: @data
      done: @addFileToTable
      fail: (e, data) =>
        error_msg = data.response().jqXHR.responseJSON.error
        @container
        .addClass('form-error')
        .prepend("<div class='input-help-text form-error-message'>
                    #{error_msg}
                  </div>")

    @container.click =>
      @container.removeClass('form-error')
      @container.find('.form-error-message').remove()


  addFileToTable: (e, data) =>
    filename = data.result.file.filename
    preview_url = "/previews/#{data.result.preview_id}"
    file_upload_id = data.result.id
    filetype = data.result.file.filename.extension()
    date = data.result.updated_at.l()

    $row = $("
      <tr data-pjax-click='preview'>
        <td><a href='#{preview_url}'>#{filename}</a></td>
        <td>#{filetype}</td><td>#{date}</td>
        <td>
          <a href='#' data-action='delete'>
            <i class='far fa-trash-alt'></i>
          </a>
        </td>
      </tr>
    ")
    @table.find('tbody').append($row)

    $input = $("
      <input
        type='hidden'
        name='#{@type_lower}[file_upload_ids][]'
        value='#{file_upload_id}'
      >
    ")
    @container.append($input)

    $row.find('[data-action=delete]').click (e) ->
      e.preventDefault()
      $row.remove()
      $input.attr('name', "#{@type_lower}[deleted_file_upload_ids][]")

    $.onmount()

$.onmount '.file-upload', ->
  new FileUploadTable(@)
